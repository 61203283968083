<template>
  <v-expansion-panels v-model="expansionPanel">
    <v-expansion-panel value="all">
      <v-expansion-panel-title
        :class="{ 'not-expandable': !isExpandable }"
        :focusable="isExpandable"
        :hide-actions="!isExpandable"
        :readonly="!isExpandable"
        :static="!isExpandable"
        class="v-expansion-title px-5"
      >
        <div class="fs-18 fw-600">
          {{ getName(householdMember) }}
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text class="px-5">
        <template v-if="editing || locked">
          <div class="mb-4">
            <HouseholdMemberFields
              @cancel="cancel"
              @household-member-changed="householdMemberUpdated"
              @remove="remove"
              @save="update"
              :autofocus="autofocus"
              :edit-mode="true"
              :hard-lock="locked"
              :household-member-values="value"
              :processing="processing"
              :self-exists="selfExists"
              :spouse-exists="spouseExists"
            />
          </div>
        </template>
        <template v-else>
          <v-row class="d-flex align-center">
            <v-col
              cols="12"
              sm="10"
            >
              <v-container class="p-0">
                <v-row
                  :dense="$vuetify.display.name === 'xs'"
                  class="mt-0"
                >
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <div class="fs-16 fw-600">
                      {{ $t('Relationship') }}
                    </div>
                    <div class="fs-16">
                      {{ $t(householdMember.relationship) }}
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <div class="fs-16 fw-600">
                      {{ $t('SSN') }}
                    </div>

                    <div class="fs-16">
                      {{ maskSSN(householdMember.ssn) }}
                      <span
                        v-if="!householdMember.ssn"
                        class="text-grey"
                      >
                        &#x2014;
                      </span>
                      <v-icon
                        @click="toggleSSN"
                        @keyup.enter="toggleSSN"
                        :icon="showSSN ? 'visibility_off' : 'visibility'"
                      />
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <div class="fs-16 fw-600">
                      {{ $t('Sex') }}
                    </div>
                    <div class="fs-16">
                      {{ $t(householdMember.gender) }}
                    </div>
                  </v-col>
                </v-row>
                <v-row
                  :dense="$vuetify.display.name === 'xs'"
                  class="mt-0"
                >
                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <div class="fs-16 fw-600">
                      {{ $t('Race') }}
                    </div>

                    <div class="fs-16">
                      {{ race }}
                      <span
                        v-if="!race"
                        class="text-grey"
                      >
                        &#x2014;
                      </span>
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <div class="fs-16 fw-600">
                      {{ $t('Ethnicity') }}
                    </div>

                    <div class="fs-16">
                      {{ $t(householdMember.ethnicity) }}
                    </div>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="4"
                  >
                    <div class="fs-16 fw-600">
                      {{ $t('Date of birth') }}
                    </div>

                    <div class="fs-16">
                      <LongDate
                        :date="householdMember.dob"
                        class="fs-16"
                        timezone="UTC"
                      />
                    </div>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
            <v-col
              v-if="canEdit"
              class="d-flex justify-end"
              cols="12"
              sm="2"
            >
              <v-btn
                @click="edit"
                :ripple="false"
                class="focus-very-visible me-3"
                color="primary"
                size="large"
                variant="outlined"
              >
                {{ $t('Edit') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
        <v-divider
          v-if="householdMemberQuestionRegister[householdMember.id]"
          class="mt-10 mb-6"
        />
        <QuestionSet
          v-if="householdMemberQuestionRegister[householdMember.id]"
          @change="changed = true"
          :attachment-group-id="subsidy.group_id"
          :attachment-owner-id="subsidy.id"
          :attachment-owner-type="'FamilySubsidy'"
          :attachment-tags-supplements="[householdMember.id]"
          :color="null"
          :key-name="householdMember.id"
          :model-value="householdMember"
          :processing="processing"
          :questions="
            getHouseholdMemberQuestions(householdMember).filter((question) => question.valid)
          "
          :schema="householdMemberSchema.definition"
          class="mb-6"
          condensed
          dense
          divided
          expanded
          hide-actions
          readonly
          tile
        />
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import HouseholdMemberFields from '@/shared/components/household-members/HouseholdMemberFields.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import LongDate from '@/shared/components/LongDate.vue';
import _ from 'lodash';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';

const eventBus = useEventBus();
const { t } = useI18n();

const props = defineProps({
  autofocus: {
    type: Boolean,
    default: false,
  },
  canEdit: {
    type: Boolean,
    default: true,
  },
  index: {
    type: Number,
    default: null,
  },
  isExpandable: {
    type: Boolean,
    default: false,
  },
  editing: {
    type: Boolean,
    default: false,
  },
  householdMemberApi: {
    type: Object,
    default: () => Api.parent.household_member,
  },
  householdMemberQuestionRegister: {
    type: Object,
    default: () => ({}),
  },
  householdMemberSchema: {
    type: Object,
    default: () => ({}),
  },
  locked: {
    type: Boolean,
    default: false,
  },
  outlined: {
    type: Boolean,
    default: false,
  },
  value: {
    type: Object,
    default: null,
  },
  preventUpdate: {
    type: Boolean,
    default: false,
  },
  selfExists: {
    default: false,
    type: Boolean,
  },
  spouseExists: {
    default: false,
    type: Boolean,
  },
  staged: {
    default: false,
    type: Boolean,
  },
  subsidy: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['cancel', 'change', 'edit', 'removal', 'save']);

const expansionPanel = ref(props.isExpandable ? [] : ['all']);
const householdMember = ref(getCopiedValue());
const processing = ref(false);
const showSSN = ref(false);

const race = computed(() => {
  return householdMember.value.race?.length > 0
    ? householdMember.value.race.map((race) => t(race).value).join(', ')
    : null;
});

function cancel() {
  householdMember.value = getCopiedValue();
  emit('cancel', householdMember.value);
}

function getHouseholdMemberQuestions(householdMember) {
  if (props.householdMemberQuestionRegister.length === 0) return [];

  return props.householdMemberQuestionRegister[householdMember.id];
}

function getName(householdMember) {
  const { first_name: firstName, middle_name: middleName, last_name: lastName } = householdMember;

  return [firstName, middleName, lastName].join(' ');
}

function maskSSN(ssn) {
  if (_.isEmpty(ssn)) return null;

  return showSSN.value ? ssn : `XXX-XX${ssn.substring(6)}`;
}

function toggleSSN() {
  showSSN.value = !showSSN.value;
}

function edit() {
  emit('edit', householdMember.value);
}

function householdMemberUpdated(newVal) {
  householdMember.value = newVal;
}

function getCopiedValue() {
  return JSON.parse(JSON.stringify(props.value));
}

async function remove() {
  if (props.staged) return;

  await props.householdMemberApi.destroy(householdMember.value.id);
  emit('removal', householdMember.value);
}

async function update() {
  if (props.staged) emit('save', householdMember.value);
  if (props.preventUpdate) return emit('change', householdMember.value);

  processing.value = true;
  const resp = await props.householdMemberApi
    .update(householdMember.value)
    .catch((error) => eventBus.error(error));
  processing.value = false;
  if (resp?.status !== 200) return null;

  emit('save', householdMember.value);
  householdMember.value = resp.data;
  return emit('change', householdMember.value);
}
</script>
