export default {
  changeMode(state, new_mode) {
    if (state.resultsMode !== 'provider') {
      state.resultsPreviousMode = state.resultsMode;
    }
    state.resultsMode = new_mode;
  },

  fullscreen(state, active) {
    state.fullscreen = active;
  },

  hide(state, dialog) {
    state[`${dialog}IsVisible`] = false;
  },

  revertMode(state) {
    state.resultsMode = state.resultsPreviousMode;
  },

  resetState() {
    if (import.meta.env.NODE_ENV !== 'test')
      throw new Error('resetState should not be called in production');
  },

  setAgeGroups(state, ageGroups) {
    state.ageGroups = ageGroups;
  },

  setAppBarBack(state, route) {
    state.appBarBack = route;
  },

  setAppBarChip(state, chip) {
    state.appBarChip = chip;
  },

  setAssignments(state, lessonId, assignments) {
    state.assignments = {
      ...state.assignments,
      [lessonId]: assignments,
    };
  },

  setBrand(state, brand) {
    state.brand = brand;
  },

  setCohort(state, cohort) {
    state.cohort = cohort;
  },

  setCourse(state, course) {
    state.course = course;
  },

  setConfig(state, config) {
    state.config = config;
  },

  setDrawer(state, drawer) {
    state.drawer = drawer;
  },

  setFeatures(state, features) {
    state.features = features;
  },

  setFilters(state, args) {
    state.filters[args[0]] = args[1];
  },

  setLanguages(state, languages) {
    state.languages = languages;
  },

  setLessons(state, lessons) {
    state.lessons = lessons;
  },

  setMiniNav(state, active) {
    state.miniNav = active;
  },

  setNavigation(state, nav) {
    state.navigation = nav;
  },

  setRole(state, role) {
    state.role = role;
  },

  setSchemas(state, schemas) {
    state.schemas = schemas;
  },

  setPages(state, pages) {
    state.pages = pages;
  },

  setProviders(state, providers) {
    state.providers = providers;
  },

  setSite(state, site) {
    state.site = site;
  },

  setSiteWebpageId(state, siteWebpageId) {
    state.siteWebpageId = siteWebpageId;
  },

  setUserSessionTimeout(state, userSessionTimeout) {
    state.userSessionTimeout = userSessionTimeout;
  },

  // For tests only
  setUserSessionActionTimestamp(state, timestamp) {
    state.userSessionActionTimestamp = timestamp;
  },

  setProfile(state, profile) {
    state.profile = profile;
  },

  setViewedSubsidyProgram(state, subsidyProgramId) {
    state.lastViewedSubsidyProgramId = subsidyProgramId;
  },

  show(state, dialog) {
    state[`${dialog}IsVisible`] = true;
  },
};
/* eslint no-param-reassign: ["error", { "props": false }] */
