import LinkedList from '@/shared/services/linked-list';
import ValueExtractor from '@/shared/services/ValueExtractor';
import Api from '@/shared/services/all_bright_finder';

/**
 * Use, organize, and validate questions from a specified subsidy program.
 *
 * @param subsidyProgram
 * @param children
 * @param subsidies
 * @param group
 *
 *
 * @returns {{
 * applicantQuestions: object
 * applicantEligibilityQuestions: object
 * applicantVerificationQuestions: object
 * childSchema: object
 * childQuestions: array
 * childConditionalEligibilityQuestions: array
 * childConditionalQuestions: array
 * childConditionalVerificationQuestions: array
 * childEligibilityQuestions: array
 * childVerificationQuestions: array
 * displayAll: boolean (ref)
 * groupSchema: object
 * groupQuestions: array
 * groupConditionalQuestions: array
 * groupEligibilityQuestions: array
 * groupVerificationQuestions: array
 * providerSchema: object
 * questions: array
 * reviewerQuestions: array
 * subsidyProgram: object
 * validApplicantEligibilityQuestionsLength: number (ref)
 * validApplicantQuestions: object
 * validApplicantQuestionsLength: number (ref)
 * validApplicantVerificationQuestionsLength: number (ref)
 * validGroupEligibilityQuestions: array
 * validGroupQuestions: array
 * validGroupVerificationQuestions: array
 * validate: function
 * lengthOfValidQuestions: function
 * mapValidQuestions: function
 * sortedQuestions: function
 * sortedVerification: function
 * loadQuestions: function
 * loadReviewModeQuestions: function
 * loadSchemas: function
 * sortLinearQuestions: function
 * }}
 *
 *
 */

export default function useSubsidy({
  subsidyProgram,
  children = [],
  subsidies = [],
  group = null,
  includeUnpublished = false,
} = {}) {
  const applicantQuestions = ref({});
  const applicantEligibilityQuestions = ref({});
  const applicantVerificationQuestions = ref({});
  const childSchema = ref(null);
  const childQuestions = ref([]);
  const childConditionalEligibilityQuestions = ref([]);
  const childConditionalQuestions = ref([]);
  const childConditionalVerificationQuestions = ref([]);
  const childEligibilityQuestions = ref([]);
  const childVerificationQuestions = ref([]);
  const displayAll = ref(includeUnpublished);
  const generalChildQuestions = ref([]);
  const generalGroupQuestions = ref([]);
  const groupSchema = ref(null);
  const groupQuestions = ref([]);
  const groupConditionalQuestions = ref([]);
  const groupEligibilityQuestions = ref([]);
  const groupVerificationQuestions = ref([]);
  const postPreferenceSelectionChildQuestions = ref([]);
  const postPreferenceSelectionGroupQuestions = ref([]);
  const providerSchema = ref(null);
  const questions = ref([]);
  const validApplicantEligibilityQuestionsLength = ref(0);
  const validApplicantQuestions = ref({});
  const validApplicantQuestionsLength = ref(0);
  const validApplicantVerificationQuestionsLength = ref(0);
  const validGroupEligibilityQuestions = ref([]);
  const validGroupQuestions = ref([]);
  const validGroupVerificationQuestions = ref([]);

  const applicantChildren = computed(() => {
    return children.value.filter(
      (child) => !!subsidies.value.find((subsidy) => subsidy.child_id == child.id),
    );
  });

  const eligibleChildren = computed(() => {
    return applicantChildren.value.filter(
      (child) =>
        !!subsidies.value.find(
          (subsidy) => subsidy.child_id == child.id && subsidy.projected_eligibility != false,
        ),
    );
  });

  // A specialist may view questions in review mode or the parent order.
  // This computedProperty allows the SubsidyApplication code's behavior to be clearer
  const reviewerQuestions = computed(() => questions.value);

  async function validate(callback) {
    applicantChildren.value.forEach((child) => {
      applicantEligibilityQuestions.value[child.id] = JSON.parse(
        JSON.stringify(childEligibilityQuestions.value),
      );
    });

    eligibleChildren.value.forEach((child) => {
      applicantQuestions.value[child.id] = JSON.parse(JSON.stringify(childQuestions.value));
      applicantVerificationQuestions.value[child.id] = JSON.parse(
        JSON.stringify(childVerificationQuestions.value),
      );
    });

    let assertions = groupConditionalQuestions.value.map((question) => {
      const value = ValueExtractor.extract(
        group.value,
        question.conditions.map((child) => child.field),
      );
      return {
        assertion: {
          conditions: question.conditions,
          labels: [question.id],
          value,
        },
      };
    });

    const childEligibilityAssertions = applicantChildren.value
      .map((child) =>
        childConditionalEligibilityQuestions.value.map((question) => {
          const value = ValueExtractor.extract(
            child,
            question.conditions.map((condition) => condition.field),
          );
          return {
            assertion: {
              conditions: question.conditions,
              labels: [question.id, child.id],
              value,
            },
          };
        }),
      )
      .flat();

    const childAssertions = eligibleChildren.value
      .map((child) =>
        childConditionalQuestions.value.map((question) => {
          const value = ValueExtractor.extract(
            child,
            question.conditions.map((condition) => condition.field),
          );
          return {
            assertion: {
              conditions: question.conditions,
              labels: [question.id, child.id],
              value,
            },
          };
        }),
      )
      .flat();

    const childVerificationAssertions = eligibleChildren.value
      .map((child) =>
        childConditionalVerificationQuestions.value.map((question) => {
          const value = ValueExtractor.extract(
            child,
            question.conditions.map((condition) => condition.field),
          );
          return {
            assertion: {
              conditions: question.conditions,
              labels: [question.id, child.id],
              value,
            },
          };
        }),
      )
      .flat();

    assertions = assertions.concat(
      childEligibilityAssertions,
      childAssertions,
      childVerificationAssertions,
    );
    const { data } = await Api.public_api.assertion.promiseBulkCreate(assertions);

    data.results.forEach((result) => {
      let question = null;
      question = groupQuestions.value.find((question) => result.labels.includes(question.id));
      if (question) {
        question.valid = result.result;
        return;
      }

      question = groupEligibilityQuestions.value.find((question) =>
        result.labels.includes(question.id),
      );
      if (question) {
        question.valid = result.result;
        return;
      }

      if (mapValidQuestions(applicantEligibilityQuestions.value, result)) {
        return true;
      }
      if (mapValidQuestions(applicantQuestions.value, result)) {
        return true;
      }
      if (mapValidQuestions(applicantVerificationQuestions.value, result)) {
        return true;
      }
    });

    validGroupEligibilityQuestions.value = groupEligibilityQuestions.value.filter(
      (question) => question.valid,
    );
    validGroupQuestions.value = groupQuestions.value.filter((question) => question.valid);
    validGroupVerificationQuestions.value = groupVerificationQuestions.value.filter(
      (question) => question.valid,
    );
    eligibleChildren.value.forEach((child) => {
      validApplicantQuestions.value[child.id] = applicantQuestions.value[child.id].filter(
        (question) => question.valid,
      );
    });

    validApplicantEligibilityQuestionsLength.value = lengthOfValidQuestions(
      applicantEligibilityQuestions.value,
    );
    validApplicantQuestionsLength.value = lengthOfValidQuestions(applicantQuestions.value);
    validApplicantVerificationQuestionsLength.value = lengthOfValidQuestions(
      applicantVerificationQuestions.value,
    );

    if (callback) {
      callback();
    }
  }

  function lengthOfValidQuestions(questions) {
    const lengths = Object.values(questions).map(
      (questionObject) => questionObject.filter((question) => question.valid).length,
    );
    return lengths.reduce((total, length) => total + length, 0);
  }

  function sortLinearQuestions(list, sortList) {
    if (!list || list.length == 0 || !sortList || sortList.length == 0) {
      return [];
    }

    const newList = [];

    sortList.forEach((id) => {
      const nextItem = list.find((item) => item.id == id);
      if (nextItem) newList.push(nextItem);
    });

    return newList;
  }

  function mapValidQuestions(col, result) {
    const id = Object.keys(col).find((childId) => result.labels.includes(childId));
    if (id) {
      const foundQuestion = col[id].find((question) => result.labels.includes(question.id));

      if (foundQuestion) {
        foundQuestion.valid = result.result;
        return true;
      }
    }
    return false;
  }

  function sortedQuestions(questions, syncedModel, eligibility) {
    return LinkedList.sort(
      questions.filter(
        (question) => question.synced_model == syncedModel && question.eligibility == eligibility,
      ),
    );
  }

  function sortedVerification(questions, syncedModel) {
    return questions.filter(
      (question) => question.synced_model == syncedModel && question.verification,
    );
  }

  function loadQuestions(callback) {
    return new Promise((resolve) => {
      Api.public_api.organization.question.index(
        { owner_id: subsidyProgram.value?.id, owner_type: 'SubsidyProgram' },
        (response) => {
          questions.value = response.data;

          childEligibilityQuestions.value = sortedQuestions(questions.value, 'Child', true).filter(
            (question) => displayAll.value || question.published,
          );

          childConditionalEligibilityQuestions.value = childEligibilityQuestions.value.filter(
            (question) => question.conditions.length > 0,
          );

          childQuestions.value = sortedQuestions(questions.value, 'Child', false).filter(
            (question) => displayAll.value || question.published,
          );

          generalChildQuestions.value = childQuestions.value.filter(
            (question) => !question.post_preference_selection,
          );

          childConditionalQuestions.value = childQuestions.value.filter(
            (question) => question.conditions.length > 0,
          );

          childVerificationQuestions.value = sortedVerification(questions.value, 'Child').filter(
            (question) => displayAll.value || question.published,
          );

          childConditionalVerificationQuestions.value = childVerificationQuestions.value.filter(
            (question) => question.conditions.length > 0,
          );

          groupEligibilityQuestions.value = sortedQuestions(questions.value, 'Group', true).filter(
            (question) => displayAll.value || question.published,
          );

          groupQuestions.value = sortedQuestions(questions.value, 'Group', false).filter(
            (question) => displayAll.value || question.published,
          );

          generalGroupQuestions.value = groupQuestions.value.filter(
            (question) => !question.post_preference_selection,
          );

          groupConditionalQuestions.value = questions.value.filter(
            (question) => question.conditions.length > 0 && question.synced_model == 'Group',
          );

          groupVerificationQuestions.value = sortedVerification(questions.value, 'Group').filter(
            (question) => displayAll.value || question.published,
          );

          postPreferenceSelectionChildQuestions.value = childQuestions.value.filter(
            (question) => question.post_preference_selection,
          );

          postPreferenceSelectionGroupQuestions.value = groupQuestions.value.filter(
            (question) => question.post_preference_selection,
          );

          if (callback) callback();
          resolve();
        },
      );
    });
  }

  // A specialist may view questions in review mode or the parent order.
  // This method resuses the questions ref, but doesn't setup refs that may not be needed
  function loadReviewModeQuestions(callback) {
    return new Promise((resolve) => {
      Api.public_api.organization.question.index(
        { owner_id: subsidyProgram.value?.id, owner_type: 'SubsidyProgram' },
        (response) => {
          questions.value = response.data;

          if (callback) callback();
          resolve();
        },
      );
    });
  }

  function loadSchemas() {
    Api.public_api.organization.schema.index((resp) => {
      groupSchema.value = resp.data.find(
        (schema) => schema.id === subsidyProgram.value.group_schema_id,
      );
      childSchema.value = resp.data.find(
        (schema) => schema.id === subsidyProgram.value.child_schema_id,
      );
      if (subsidyProgram.value.provider_schema_id) {
        providerSchema.value = resp.data.find(
          (schema) => schema.id === subsidyProgram.value.provider_schema_id,
        );
      }
    });
  }

  return {
    applicantChildren,
    applicantQuestions,
    applicantEligibilityQuestions,
    applicantVerificationQuestions,
    childSchema,
    childQuestions,
    childConditionalEligibilityQuestions,
    childConditionalQuestions,
    childConditionalVerificationQuestions,
    childEligibilityQuestions,
    childVerificationQuestions,
    eligibleChildren,
    generalChildQuestions,
    generalGroupQuestions,
    groupSchema,
    groupQuestions,
    groupConditionalQuestions,
    groupEligibilityQuestions,
    groupVerificationQuestions,
    postPreferenceSelectionChildQuestions,
    postPreferenceSelectionGroupQuestions,
    providerSchema,
    questions,
    reviewerQuestions,
    subsidyProgram,
    validApplicantEligibilityQuestionsLength,
    validApplicantQuestions,
    validApplicantQuestionsLength,
    validApplicantVerificationQuestionsLength,
    validGroupEligibilityQuestions,
    validGroupQuestions,
    validGroupVerificationQuestions,
    validate,
    lengthOfValidQuestions,
    mapValidQuestions,
    sortedQuestions,
    sortedVerification,
    loadQuestions,
    loadReviewModeQuestions,
    loadSchemas,
    sortLinearQuestions,
  };
}
