<template>
  <v-card
    :border="outlined"
    :elevation="elevation"
    class="mb-4"
    break-after
    tile
  >
    <template v-if="name">
      <v-card-title :class="!outlined ? 'bb-1 bc-very-light-grey' : ''">
        <v-row>
          <v-col class="d-flex flex-grow-1 flex-shrink-0">
            <h2
              :aria-label="[name, overallText].join(', ')"
              class="focus-very-visible text-wrap"
              tabindex="0"
            >
              <span
                v-text="name"
                class="me-3 fs-20 fw-500"
              />

              <v-chip
                :color="overallColor"
                :data-color="overallColor"
                data-testid="eligibility-chip"
              >
                <v-icon
                  :data-icon="overallIcon"
                  class="me-2"
                  color="white"
                  data-testid="eligibility-icon"
                  role="presentation"
                  aria-hidden
                >
                  {{ overallIcon }}
                </v-icon>
                <span data-cy="eligibility-text">{{ overallText }}</span>
              </v-chip>
            </h2>
          </v-col>
          <v-col class="d-flex justify-end flex-shrink-0">
            <v-btn
              @click="criteriaIsVisible = !criteriaIsVisible"
              :aria-label="$t('See why, expand to learn more')"
              class="focus-very-visible"
              variant="text"
            >
              <span
                v-t="'See why'"
                class="fs-16 c-light-black"
              />
              <v-icon class="c-light-black ms-2"> expand_more </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider
        v-show="criteriaIsVisible"
        class="mb-5"
      />
    </template>

    <v-card-text
      v-show="criteriaIsVisible"
      class="pt-0"
    >
      <div
        v-for="(source, index) in displayedFundingSources"
        :key="index"
        class="mt-2"
      >
        <div class="d-flex align-start mb-0">
          <div>
            <div
              :aria-label="[$t('Funding source'), source.name].join(': ')"
              class="fs-18 fw-600 mb-0"
              tabindex="0"
            >
              {{ $t(source.name) }}
            </div>
            <div
              v-if="source.description"
              class="fs-16 c-light-black mb-2"
              tabindex="0"
            >
              {{ $t(source.description) }}
            </div>
          </div>
        </div>

        <div
          v-for="result in source.criteria_results"
          :key="result.message"
          :aria-label="
            [$t('Criteria'), $t(result.passes ? 'passing' : 'failing'), result.message].join(', ')
          "
          class="d-flex align-center mb-1"
          tabindex="0"
        >
          <v-icon
            :color="result.passes == true ? 'green' : result.passes == false ? 'red' : 'grey'"
            class="me-2"
            size="20"
          >
            {{
              result.passes == true
                ? 'check_circle'
                : result.passes == false
                  ? 'cancel'
                  : 'check_circle'
            }}
          </v-icon>
          <span class="fs-16">
            {{ $t(result.message) }}
          </span>
        </div>

        <template v-if="index + 1 < displayedFundingSources.length">
          <div class="px-2">
            <v-divider class="mt-4 mb-3" />
          </div>
        </template>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup>
import useTranslator from '@/shared/composables/useTranslator';
const translator = useTranslator();

const props = defineProps({
  elevation: {
    default: 2,
    type: Number,
  },
  expanded: {
    default: false,
    type: Boolean,
  },
  fundingSources: {
    default: () => [],
    type: Array,
  },
  name: {
    default: null,
    type: String,
  },
  outlined: {
    default: false,
    type: Boolean,
  },
  projectedEligibility: {
    type: Boolean,
    default: null,
  },
});

const criteriaIsVisible = ref(props.expanded);

const displayedFundingSources = computed(() => {
  if (props.projectedEligibility) {
    return props.fundingSources.filter((fs) => fs.criteria_results.every((cr) => cr.passes));
  }

  return props.fundingSources;
});

const overallColor = computed(() => {
  return props.projectedEligibility ? 'green' : 'red';
});

const overallIcon = computed(() => {
  return props.projectedEligibility ? 'check_circle' : 'cancel';
});

const overallText = computed(() => {
  return props.projectedEligibility
    ? translator.$t('Eligible').value
    : translator.$t('Not eligible').value;
});
</script>
