import Assets from '@/shared/services/assets';

export default {
  slashify(dateString) {
    const [year, month, day] = dateString.split('-');
    return `${month}/${day}/${year}`;
  },

  sortYoungestDobFirst(arrayOfChildrenWithDob) {
    return arrayOfChildrenWithDob.sort((a, b) => new Date(b.dob) - new Date(a.dob));
  },

  isoToShort(string) {
    const parts = string.split('-');
    return [Assets.months[parseInt(parts[1])], parts[2]].join(' ');
  },

  msInMonth() {
    return 1000 * 60 * 60 * 24 * 30;
  },
};
