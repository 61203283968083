import DateParser from '@/shared/services/date_parser.js';

export class SearchParams {
  constructor(search, { ageGroups = null, listFilterId = null, dobSearch = false } = {}) {
    this.search = search;
    this.children = search.children;
    this.listFilterId = listFilterId;

    if (ageGroups) {
      this.children = ageGroups.filter((ageGroup) => ageGroup.count > 0);
    } else if (this.children && !dobSearch) {
      this.children = DateParser.sortYoungestDobFirst(this.children);
    } else {
      this.children = [];
    }
  }

  asJson() {
    return {
      categories: this.search.categories,
      children: this.children,
      county: this.search.county,
      custom: this.search.custom,
      destination: this.search.destination,
      distance: this.search.distance,
      dropoff: this.search.dropoff,
      exclude_closed: this.search.exclude_closed,
      formatted_destination: this.search.formatted_destination,
      formatted_origin: this.search.formatted_origin,
      highlights: this.search.highlights,
      include_all_children: this.search.include_all_children,
      license_type: this.search.license_type,
      location_type: this.search.location_type,
      ne_lat: this.search.ne_lat,
      ne_lng: this.search.ne_lng,
      minimum_quality_rating: this.search.minimum_quality_rating,
      origin: this.search.origin,
      payment_subsidies_accepted: this.search.payment_subsidies_accepted,
      payment_other_programs: this.search.payment_other_programs,
      pickup: this.search.pickup,
      program_season: this.search.program_season,
      program_types: this.search.program_types,
      schema_id: this.search.schema_id,
      start_date: this.search.start_date,
      subsidy: this.search.subsidy,
      subsidy_program_id: this.search.subsidy_program_id,
      sw_lat: this.search.sw_lat,
      sw_lng: this.search.sw_lng,
      travel_mode: this.search.travel_mode,
      unformatted_origin: this.search.unformatted_origin,
      week_days: this.search.week_days,
      vacancy: this.search.vacancy,
      zip: this.search.zip || this.search.origin.zip,
      list_filter_id: this.listFilterId,
    };
  }
}
