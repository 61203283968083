<!--  eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <div
      v-if="$store.state.schemas[value.schema_id].definition.properties.closed_status.filterable"
    >
      <v-row>
        <v-col>
          <div class="c-black fs-16 fw-600 mb-2">
            {{ $t('Open') }}
          </div>
          <v-checkbox
            v-model="value.exclude_closed"
            @update:model-value="$emit('change')"
            class="mt-0"
            color="primary"
            false-icon="check_box_outline_blank"
            true-icon="check_box"
            hide-details
          >
            <template #label>
              <span class="c-black">{{ $t('Exclude providers that are closed') }}</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="my-6" />
    </div>

    <div
      v-if="$store.state.schemas[value.schema_id].definition.properties.accepts_subsidy.filterable"
    >
      <v-row>
        <v-col>
          <div class="c-black fs-16 fw-600 mb-2">
            {{ $t('Financial aid') }}
          </div>

          <v-checkbox
            v-model="value.subsidy"
            @update:model-value="$emit('change')"
            class="c-black mt-0"
            color="primary"
            hide-details
          >
            <template #label>
              <span class="c-black">{{
                $t('Only show results that have financial aid options available')
              }}</span>
            </template>
          </v-checkbox>
        </v-col>
      </v-row>
      <v-divider class="my-6" />
    </div>

    <div
      v-if="
        $store.state.schemas[value.schema_id].definition.properties.payment_subsidies_accepted
          .filterable
      "
    >
      <v-row>
        <v-col data-cy="subsidy-section">
          <div
            class="c-black fs-16 fw-600 mb-2"
            data-cy="financial-assistance"
          >
            {{ $t('Financial assistance') }}
          </div>

          <v-checkbox
            v-for="option in $store.state.schemas[value.schema_id].definition.properties
              .payment_subsidies_accepted.items.enum"
            v-model="value.payment_subsidies_accepted"
            @update:model-value="$emit('change')"
            :key="option"
            :label="$t(option)"
            :value="option"
            class="c-black mt-0"
            color="primary"
            hide-details
            multiple
          />
        </v-col>
      </v-row>
      <v-divider class="my-6" />
    </div>

    <div
      v-if="
        $store.state.schemas[value.schema_id].definition.properties.payment_other_programs
          .filterable
      "
    >
      <v-row>
        <v-col>
          <div class="c-black fs-16 fw-600 mb-2">
            {{
              $t(
                $store.state.schemas[value.schema_id].definition.properties.payment_other_programs
                  .alias || 'Head start programs offered',
              )
            }}
          </div>

          <v-checkbox
            v-for="option in $store.state.schemas[value.schema_id].definition.properties
              .payment_other_programs.items.enum"
            v-model="value.payment_other_programs"
            @update:model-value="$emit('change')"
            :key="option"
            :label="$t(option)"
            :value="option"
            class="c-black mt-0"
            color="primary"
            hide-details
            multiple
          />
        </v-col>
      </v-row>
      <v-divider class="my-6" />
    </div>

    <div
      v-for="customFilter in customFilters"
      :key="customFilter[0]"
    >
      <div class="pb-0 c-black fs-16 fw-600 mb-2">
        {{ $t(customFilter[1].alias) }}
      </div>
      <template v-if="customFilter[1].type == 'array' || customFilter[1].type == 'string'">
        <v-row
          v-if="(customFilter[1].enum || customFilter[1].items.enum).length < 20"
          dense
        >
          <v-col
            v-for="(option, optionIndex) in customFilter[1].enum || customFilter[1].items.enum"
            :key="option + optionIndex"
            cols="6"
            md="6"
          >
            <v-checkbox
              v-model="value.custom[customFilter[0]]"
              @update:model-value="$emit('change')"
              :multiple="customFilter[1].type == 'array'"
              :value="option"
              class="mt-0"
              color="primary"
              false-icon="check_box_outline_blank"
              true-icon="check_box"
              hide-details
            >
              <template #label>
                <span class="c-black">
                  {{ $t(option) }}
                </span>
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
        <v-row
          v-else
          dense
        >
          <v-autocomplete
            v-model="value.custom[customFilter[0]]"
            :items="customFilter[1].enum || customFilter[1].items.enum"
            :multiple="customFilter[1].type == 'array'"
            variant="filled"
            chips
            closable-chips
          />
        </v-row>
      </template>
      <template v-if="customFilter[1].type == 'boolean'">
        <v-row dense>
          <v-col>
            <v-checkbox
              v-model="value.custom[customFilter[0]]"
              :false-value="null"
              :label="$t('Yes')"
              :true-value="true"
              class="mx-0 my-1 fs-16"
              hide-details
            />
            <v-checkbox
              v-model="value.custom[customFilter[0]]"
              :false-value="null"
              :label="$t('No')"
              :true-value="false"
              class="mx-0 my-1 fs-16"
              hide-details
            />
          </v-col>
        </v-row>
      </template>
      <v-divider class="my-6" />
    </div>

    <div v-if="$store.state.schemas[value.schema_id].definition.properties.highlights.filterable">
      <div
        v-for="(highlight, highlightIndex) in highlightOptions"
        :key="highlightIndex"
      >
        <template v-if="highlight.filterable">
          <div class="pb-0 c-black fs-16 fw-600 mb-2">
            {{ $t(highlight.alias) }}
          </div>
          <v-row
            v-if="highlight.items.enum.length < 20"
            dense
          >
            <v-col
              v-for="(option, optionIndex) in highlight.items.enum"
              :key="option + optionIndex"
              cols="6"
              md="6"
            >
              <v-checkbox
                v-model="value.highlights[highlightKeys[highlightIndex]]"
                @update:model-value="$emit('change')"
                :value="option"
                class="mt-0"
                color="primary"
                false-icon="check_box_outline_blank"
                true-icon="check_box"
                hide-details
                multiple
              >
                <template #label>
                  <span class="c-black">
                    {{ $t(option) }}
                  </span>
                </template>
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row
            v-else
            dense
          >
            <v-col>
              <v-autocomplete
                v-model="value.highlights[highlightKeys[highlightIndex]]"
                :item-title="(item) => $t(item.text)"
                :item-value="(item) => item.value"
                :items="translateableHighlight(highlight)"
                variant="filled"
                chips
                closable-chips
                hide-details
                multiple
              />
            </v-col>
          </v-row>
          <v-divider class="my-6" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    value: {
      type: Object,
      default: null,
    },
  },

  emits: ['change'],

  data() {
    return {
      customFilters: this.getCustomFilters(),
      highlightOptions: Object.values(this.getHighlightProperties()),
      highlightKeys: Object.keys(this.getHighlightProperties()),
    };
  },

  watch: {
    // eslint-disable-next-line func-names
    'value.schema_id': function () {
      Object.assign(this.$data, this.$options.data.apply(this));
    },
  },

  methods: {
    getCustomFilters() {
      return Object.entries(
        this.$store.state.schemas[this.value.schema_id].definition.properties.custom.properties,
      ).filter((property) => property[1].filterable);
    },

    getHighlightProperties() {
      return this.$store.state.schemas[this.value.schema_id].definition.properties.highlights
        .properties;
    },

    translateableHighlight(highlight) {
      return highlight.items.enum.map((item) => ({ text: item, value: item }));
    },
  },
};
</script>
