<template>
  <span>
    <span
      v-if="element.type == 'text'"
      :class="element.class"
      class="me-1"
      style="word-break: break-word"
      >{{ $t(element.text) }}</span
    >
    <span v-if="element.type == 'link'">
      <a
        :class="element.class"
        :href="element.href"
        class="me-1"
        style="word-break: break-all"
        target="_blank"
        >{{ $t(element.text) }}</a
      >
    </span>
    <br v-if="element.type == 'break'" />
  </span>
</template>

<script>
export default {
  compatConfig: { MODE: 3 },

  props: {
    element: {
      type: Object,
      default: null,
    },
  },
};
</script>
