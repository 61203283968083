<template>
  <div class="fill-height bg-super-light-blue d-flex">
    <div
      :style="providerClass"
      class="va-top"
    >
      <div
        v-if="schema"
        class="pa-3"
      >
        <ProviderMatch
          v-if="selectedMatch && selectedMatch && $store.state.resultsMode == 'provider'"
          @close="closeProvider()"
          @favorite:add="$emit('favorite:add', $event)"
          @favorite:list="$emit('favorite:list', $event)"
          @favorite:remove="$emit('favorite:remove', $event)"
          @refresh="$emit('refresh', selectedMatch.id)"
          :favorites="favorites"
          :lists="lists"
          :match="selectedMatch"
          :schema="schema"
          :search="search"
        />
      </div>
    </div>

    <div
      id="scrollable_results"
      :style="listStyle"
      class="va-top focus-very-visible"
    >
      <div
        v-if="$store.state.pages.Search.support_alert"
        class="px-4"
      >
        <h1>
          <MarkdownContent :content="$store.state.pages.Search.support_alert" />
        </h1>
      </div>

      <div
        v-if="
          $store.state.pages.Search.structured_support_alert &&
          $store.state.pages.Search.structured_support_alert.length > 0
        "
        class="px-4 pt-3 pb-2"
      >
        <StructuredText :text="$store.state.pages.Search.structured_support_alert" />
      </div>

      <div
        v-if="
          $store.state.pages.Search.program_alert && $store.state.pages.Search.program_alert.url
        "
        class="px-3"
      >
        <ProgramAlert />
      </div>

      <v-card
        v-show="!processing && matches.length == 0"
        class="elevation-0 mx-3 bg-none mt-6"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <p
                ref="noMatch"
                class="focus-lightly-visible fs-16 fw-500 pa-4 ta-center"
                tabindex="0"
              >
                {{
                  $t(
                    search.id
                      ? 'No providers matched your search with the filters you have. Try expanding your filters to see more results.'
                      : 'Apply a filter above to begin a search',
                  )
                }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-img
                :src="$a.url('icons.nullfilters')"
                alt="No match"
                max-height="300px"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card
        v-show="processing"
        class="elevation-0 mx-3"
      >
        <v-card-text>
          <v-row class="mt-6">
            <v-col>
              <v-progress-linear
                indeterminate
                stream
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <template v-if="schema">
        <div
          v-for="(match, index) in matches"
          :id="'result_link_' + match.id"
          :key="match.id"
          :data-meta="data_meta(match.id)"
          class="px-3 pt-3"
          tracked
        >
          <v-card
            ref="match"
            :ripple="false"
            class="pa-0"
            border
            flat
            tile
          >
            <v-card-text>
              <b-match
                @favorite:add="$emit('favorite:add', $event)"
                @favorite:list="$emit('favorite:list', $event)"
                @favorite:remove="$emit('favorite:remove', $event)"
                @mouseover="selectedIndex = index"
                :id="match.id"
                :key="match.id"
                :favorites="favorites"
                :lists="lists"
                :match="match"
                :schema="schema"
                :search="search"
              />
            </v-card-text>
            <v-card-actions
              v-if="$store.state.pages.Search.features.enable_financial_aid_calculator"
              @click="openCalculator"
              class="bt-1 pt-3 bc-extra-light-grey"
            >
              <TuitionCredit
                :inputs="calculatorInputs"
                :provider="match"
              />
            </v-card-actions>
            <v-card-actions
              @click="select(index)"
              @keyup.enter="select(index)"
              class="bt-1 pt-3 bc-extra-light-grey pointer"
              tabindex="0"
            >
              <div
                class="fs-14 fw-400"
                data-cy="match-view-more"
              >
                {{ $t('View more information') }}
              </div>
              <v-spacer />
              <v-icon class="fs-22 fw-500"> chevron_right </v-icon>
            </v-card-actions>
          </v-card>
        </div>
      </template>

      <ResourceDialog
        @save="calculateFinancialAid"
        ref="calculatorDialog"
        :fields="calculatorFields"
        :max-width="600"
        save-button-text="Estimate tuition credit"
        title="Estimate my DPP Tuition Credit"
      >
        <template #description>
          <p class="c-light-black fs-14">
            {{
              $t(`*Complete the form below to see the potential tuition credit your family may be eligible \
to receive. DPP tuition credits are currently available for all 4-year-olds and select 3-year-olds who reside \
in the City or County of Denver. Family size = The total number of people who live in your household, including \
yourself and your 3 or 4-year-old child. Gross Annual Income = The total amount of income you have earned in a year \
from all sources before taxes.`)
            }}
          </p>
        </template>
      </ResourceDialog>

      <VerticalSpacer />

      <v-card
        v-show="!processing && pages > 1"
        class="bottom-0 elevation-0 bg-super-light-blue w-100pc pb-2 pt-1"
        tile
      >
        <v-pagination
          @update:model-value="$emit('change:page', $event)"
          :length="pages"
          :model-value="page"
          :total-visible="8"
          class="custom"
          rounded
        />
      </v-card>
    </div>

    <div
      :style="mapStyle"
      class="focus-very-visible va-top"
      style="position: relative"
    >
      <div :style="redoSearchButtonStyle">
        <v-btn
          @click="redoSearch()"
          id="redo_link"
          :class="showRedoSearchHere ? 'bg-white' : 'bg-transparent'"
          :disabled="map_changed_processing || !showRedoSearchHere"
          :ripple="false"
          :variant="showRedoSearchHere ? 'flat' : 'tonal'"
          rounded
        >
          <v-progress-circular
            v-show="map_changed_processing"
            class="me-2"
            color="gray"
            indeterminate=""
            size="15"
          />
          <span>{{ $t('Redo search here') }}</span>
        </v-btn>
      </div>

      <GoogleMap
        @center_changed="mapChanged"
        @zoom_changed="mapChanged"
        ref="mapRef"
        :api-promise="apiPromise"
        :center="map_center"
        :disable-default-ui="true"
        :scale-control="true"
        :zoom="zoom"
        gesture-handling="greedy"
        style="width: 100%; height: 100%"
        zoom-control
      >
        <GoogleMapMarker
          v-if="map_origin"
          :options="{
            position: map_origin,
            icon: search.location_type == 'home' ? $a.url('icons.home') : $a.url('icons.business'),
          }"
        />
        <GoogleMapMarker
          v-if="map_destination && search.location_type == 'commute'"
          :options="{
            position: map_destination,
            icon: $a.url('icons.business'),
          }"
        />
        <MatchMarker
          v-for="(match, index) in matches"
          @click="select(index)"
          :key="match.id"
          :match="match"
          :selected="match == selectedMatch"
        />
      </GoogleMap>
    </div>

    <v-btn
      @click="$store.commit('changeMode', displayListFab ? 'map' : 'list')"
      :ripple="false"
      :style="{
        position: 'fixed',
        bottom: '30px',
        right: '50%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: '150px',
      }"
      class="d-md-none"
      color="primary"
      elevation="1"
      rounded
      >{{ $t(displayListFab ? 'Map view' : 'List view') }}</v-btn
    >
  </div>
</template>

<script>
import MarkdownContent from '@/shared/components/MarkdownContent.vue';
import Match from '@/shared/components/search/match.vue';
import MatchMarker from '@/shared/components/map/MatchMarker.vue';
import ProgramAlert from '@/shared/components/search/ProgramAlert.vue';
import ProviderMatch from '@/shared/components/search/ProviderMatch.vue';
import StructuredText from '@/shared/components/content/StructuredText.vue';
import TuitionCredit from '@/shared/components/TuitionCredit.vue';
import { Coord } from '@/shared/services/coord';
import { RouteDispatcher } from '@/shared/services/route_dispatcher';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { GoogleMap, Marker as GoogleMapMarker } from 'vue3-google-map';
import { Loader as GoogleMapLoader } from '@googlemaps/js-api-loader';

const loader = new GoogleMapLoader({
  apiKey: import.meta.env.VUE_APP_MAPS_KEY,
  version: 'weekly',
  libraries: ['places'],
});

/* eslint-disable vue/multi-word-component-names, no-undef, func-names */

export default {
  compatConfig: { MODE: 3 },

  name: 'Results',

  components: {
    MarkdownContent,
    MatchMarker,
    ProgramAlert,
    ProviderMatch,
    ResourceDialog,
    StructuredText,
    TuitionCredit,
    VerticalSpacer,
    BMatch: Match,
    GoogleMap,
    GoogleMapMarker,
  },

  props: {
    defaultCenter: {
      type: Object,
      default: null,
    },
    favorites: {
      type: Array,
      default: null,
    },
    lists: {
      type: Array,
      default: null,
    },
    loaded: {
      type: Boolean,
      default: null,
    },
    matches: {
      type: Array,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
    pages: {
      type: Number,
      default: null,
    },
    processing: {
      type: Boolean,
      default: null,
    },
    search: {
      type: Object,
      default: null,
    },
    top: {
      type: Number,
      default: null,
    },
  },

  emits: ['change:page', 'favorite:add', 'favorite:list', 'favorite:remove', 'redo', 'refresh'],

  setup() {
    return { apiPromise: loader.load() };
  },

  data() {
    return {
      calculatorFields: [
        {
          cols: '6',
          text: 'Family size',
          value: 'family_size',
          items: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
        },
        {
          cols: '6',
          text: 'Gross annual income',
          type: 'currency',
          value: 'income',
        },
        {
          cols: '6',
          text: 'Participation',
          value: 'participation',
          items: ['Extended day', 'Full day', 'Half day'],
        },
        {
          cols: '6',
          text: 'School year',
          value: 'school_year',
          items: ['2023-2024'],
        },
      ],
      calculatedValue: null,
      calculatorInputs: {
        family_size: null,
        income: null,
        participation: null,
        school_year: null,
        saved: false,
      },
      transit_time: 0,
      directionsService: null,
      directionDisplay: null,
      dispatcher: null,
      extended: false,
      infoOpened: false,
      infoOptions: { content: '' },
      map_changed: false,
      map_changed_lock: true,
      map_changed_processing: false,
      previouslySelectedIndex: null,
      secondaryDirectionDisplay: null,
      selectedIndex: null,
      transitionName: '',
      toggle_view: 0,
      query: '',
      zoom: 7,
    };
  },

  computed: {
    displayListFab() {
      return (
        this.$store.state.resultsMode === 'list' ||
        (this.$store.state.resultsMode === 'provider' &&
          this.$store.state.resultsPreviousMode === 'list')
      );
    },

    mapTop() {
      if (this.$store.state.pages.Search.features.enable_filters) {
        return 60 + this.top;
      }
      return this.top;
    },

    listStyle() {
      if (this.$store.state.resultsMode === 'provider') {
        return 'display: none;';
      }
      if (this.$vuetify.display.mdAndUp) {
        return `height: 100%; width: 50%; overflow-y: auto; padding-top: ${this.mapTop}px;`;
      }
      if (this.$store.state.resultsMode === 'list') {
        return `width: 100%; overflow-y: scroll; height: 100%; padding-top: ${this.mapTop}px;`;
      }
      return 'display: none;';
    },

    mapStyle() {
      if (this.$vuetify.display.mdAndUp) {
        return `height: 100%; width: 50%; text-align: right; padding-top: ${this.mapTop}px;`;
      }
      if (this.$store.state.resultsMode === 'map') {
        return `width: 100%; overflow-y: scroll; height: 100%; text-align: right; padding-top: ${this.mapTop}px;`;
      }
      return 'display: none;';
    },

    map_center() {
      if (this.search && this.search.center) {
        return new Coord(this.search.center);
      }
      return this.defaultCenter;
    },

    map_destination() {
      if (this.search && this.search.destination && this.search.destination.latitude) {
        return new Coord(this.search.destination);
      }

      return null;
    },

    map_origin() {
      if (this.search && this.search.origin && this.search.origin.latitude) {
        return new Coord(this.search.origin);
      }

      return null;
    },

    providerClass() {
      const topPadding = this.$store.state.pages.Search.features.enable_filters
        ? 60 + this.top
        : 0 + this.top;
      if (this.$store.state.resultsMode !== 'provider') {
        return 'display: none;';
      }
      if (this.$vuetify.display.mdAndUp) {
        return `height: 100%; width: 50%; overflow-y: auto; padding-top: ${topPadding}px;`;
      }
      return `width: 100%; overflow-y: scroll; height: 100%; padding-top: ${topPadding}px;`;
    },

    redoSearchButtonStyle() {
      const style = ['position: absolute'];
      style.push(`top: ${27 + this.mapTop}px`);
      style.push('z-index: 1');
      style.push('margin-right: 20px');
      if (this.$vuetify.rtl) {
        style.push('left: 20px');
      } else {
        style.push('right: 20px');
      }
      return style.join(';');
    },

    schema() {
      if (this.search?.schema_id) {
        return this.$store.state.schemas[this.search.schema_id];
      }
      return null;
    },

    selectedMatch() {
      if (this.selectedIndex == null) return null;

      return this.matches[this.selectedIndex];
    },

    showRedoSearchHere() {
      return (
        this.map_changed &&
        (this.$store.state.resultsMode === 'map' || this.$vuetify.display.mdAndUp)
      );
    },

    style() {
      if (this.top) {
        return `position: fixed; bottom: 0px; width: 100%; top: ${this.top}px;`;
      }
      return 'position: fixed; bottom: 0px; width: 100%; top: 136px;';
    },
  },

  watch: {
    '$store.state.resultsMode': function (newVal) {
      if (newVal === 'list') {
        if (this.previouslySelectedIndex) {
          setTimeout(() => {
            this.$refs.match[this.previouslySelectedIndex].$el.focus();
          }, 200);
        }
      } else {
        this.$store.commit('setExpandAppBarSmAndDown', false);
        if (newVal === 'map') {
          this.triggerRefit();
        }
      }
    },
  },

  async mounted() {
    await this.apiPromise;
    await this.$nextTick();
    this.setMap();
  },

  methods: {
    calculateFinancialAid(newVal) {
      this.calculatorInputs = newVal;
      this.calculatorInputs.saved = true;
      this.$refs.calculatorDialog?.close();
    },

    closeProvider() {
      this.previouslySelectedIndex = this.selectedIndex;
      this.selectedIndex = null;
      this.$store.commit('revertMode');
      this.fitMap();
    },

    data_meta(id) {
      return JSON.stringify({ provider_id: id });
    },

    fitMap() {
      this.map_changed = false;
      this.map_changed_lock = false;
      this.map_changed_processing = false;

      if (this.dispatcher) {
        this.dispatcher.clear();
      }

      if (this.search.sw_lat || this.matches.length === 0) {
        return;
      }

      const bounds = new google.maps.LatLngBounds();
      if (this.map_origin) {
        bounds.extend(this.map_origin);
      }
      if (this.location_type === 'commute' && this.map_destination) {
        bounds.extend(this.map_destination);
      }
      for (let i = 0; i < this.matches.length; i += 1) {
        bounds.extend(new Coord(this.matches[i]));
      }
      this.$refs.mapRef?.map.fitBounds(bounds);
    },

    mapChanged() {
      if (!this.map_changed_lock) {
        this.map_changed = true;
      }
    },

    openCalculator() {
      return this.$refs.calculatorDialog?.open(JSON.parse(JSON.stringify(this.calculatorInputs)));
    },

    redoSearch() {
      if (!this.$refs.mapRef) return;

      this.map_changed_processing = true;
      this.$emit('redo', [
        this.$refs.mapRef.map.getBounds().getSouthWest(),
        this.$refs.mapRef.map.getBounds().getNorthEast(),
      ]);
    },

    select(index) {
      this.selectedIndex = index;
      this.$store.commit('changeMode', 'provider');
      if (this.dispatcher) {
        this.dispatcher.clear();
        if (this.map_origin && this.map_destination && this.search.location_type === 'commute') {
          this.dispatcher.dispatch(
            this.map_origin,
            this.map_destination,
            this.search.travel_mode,
            [{ location: new Coord(this.selectedMatch), stopover: true }],
            (transitTime) => {
              this.selectedMatch.transit_time = transitTime;
            },
          );
        } else if (this.map_origin) {
          this.dispatcher.dispatch(
            this.map_origin,
            new Coord(this.selectedMatch),
            this.search.travel_mode,
            [],
            (transitTime) => {
              this.selectedMatch.transit_time = transitTime;
            },
          );
        }
      }
    },

    setMap() {
      this.$store.commit('changeMode', 'list');
      this.selectedIndex = null;
      if (!this.dispatcher) {
        this.dispatcher = new RouteDispatcher(this.$refs.mapRef.map);
      } else {
        this.dispatcher.clear();
      }
      this.fitMap();
    },

    triggerRefit() {
      setTimeout(() => {
        this.fitMap();
      }, 100);
    },
  },
};
</script>

<style scoped>
.custom {
  padding-top: 10px;
}
</style>
