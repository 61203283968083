import LinkedList from '@/shared/services/linked-list';
import ValueExtractor from '@/shared/services/ValueExtractor';

/**
 * @deprecated This mixin is replaced by useSubsidy.js
 * @example ```vue
 * <script setup>
 * import useSubsidy from '@/shared/composables/useSubsidy';
 *
 * const { groupSchema, childSchema, providerSchema, loadSchemas } = useSubsidy({ subsidyProgram: subsidyProgram, children: children, subsidies: subsidies, group: group });
 * </script>
 * ```
 */

export default {
  computed: {
    applicantChildren() {
      return this.children.filter(
        (child) => !!this.subsidies.find((subsidy) => subsidy.child_id == child.id),
      );
    },

    eligibleChildren() {
      return this.applicantChildren.filter(
        (child) =>
          !!this.subsidies.find(
            (subsidy) => subsidy.child_id == child.id && subsidy.projected_eligibility != false,
          ),
      );
    },
  },

  data() {
    return {
      applicantQuestions: {},
      applicantEligibilityQuestions: {},
      applicantVerificationQuestions: {},
      childSchema: null,
      childQuestions: [],
      childConditionalEligibilityQuestions: [],
      childConditionalQuestions: [],
      childConditionalVerificationQuestions: [],
      childEligibilityQuestions: [],
      childVerificationQuestions: [],
      displayAll: false,
      groupSchema: null,
      groupQuestions: [],
      groupConditionalQuestions: [],
      groupEligibilityQuestions: [],
      groupVerificationQuestions: [],
      providerSchema: null,
      questions: [],
      subsidyProgram: null,
      validApplicantEligibilityQuestionsLength: 0,
      validApplicantQuestions: {},
      validApplicantQuestionsLength: 0,
      validApplicantVerificationQuestionsLength: 0,
      validGroupEligibilityQuestions: [],
      validGroupQuestions: [],
      validGroupVerificationQuestions: [],
    };
  },

  methods: {
    async validate(callback) {
      this.applicantChildren.forEach((child) => {
        this.applicantEligibilityQuestions[child.id] = JSON.parse(
          JSON.stringify(this.childEligibilityQuestions),
        );
      });

      this.eligibleChildren.forEach((child) => {
        this.applicantQuestions[child.id] = JSON.parse(JSON.stringify(this.childQuestions));
        this.applicantVerificationQuestions[child.id] = JSON.parse(
          JSON.stringify(this.childVerificationQuestions),
        );
      });

      let assertions = this.groupConditionalQuestions.map((question) => {
        const value = ValueExtractor.extract(
          this.group,
          question.conditions.map((child) => child.field),
        );
        return {
          assertion: {
            conditions: question.conditions,
            labels: [question.id],
            value,
          },
        };
      });

      const childEligibilityAssertions = this.applicantChildren
        .map((child) =>
          this.childConditionalEligibilityQuestions.map((question) => {
            const value = ValueExtractor.extract(
              child,
              question.conditions.map((condition) => condition.field),
            );
            return {
              assertion: {
                conditions: question.conditions,
                labels: [question.id, child.id],
                value,
              },
            };
          }),
        )
        .flat();

      const childAssertions = this.eligibleChildren
        .map((child) =>
          this.childConditionalQuestions.map((question) => {
            const value = ValueExtractor.extract(
              child,
              question.conditions.map((condition) => condition.field),
            );
            return {
              assertion: {
                conditions: question.conditions,
                labels: [question.id, child.id],
                value,
              },
            };
          }),
        )
        .flat();

      const childVerificationAssertions = this.eligibleChildren
        .map((child) =>
          this.childConditionalVerificationQuestions.map((question) => {
            const value = ValueExtractor.extract(
              child,
              question.conditions.map((condition) => condition.field),
            );
            return {
              assertion: {
                conditions: question.conditions,
                labels: [question.id, child.id],
                value,
              },
            };
          }),
        )
        .flat();

      assertions = assertions.concat(
        childEligibilityAssertions,
        childAssertions,
        childVerificationAssertions,
      );
      const { data } = await this.api.public_api.assertion.promiseBulkCreate(assertions);

      data.results.forEach((result) => {
        let question = null;
        question = this.groupQuestions.find((question) => result.labels.includes(question.id));
        if (question) {
          question.valid = result.result;
          return;
        }

        question = this.groupEligibilityQuestions.find((question) =>
          result.labels.includes(question.id),
        );
        if (question) {
          question.valid = result.result;
          return;
        }

        question = this.groupVerificationQuestions.find((question) =>
          result.labels.includes(question.id),
        );
        if (question) {
          question.valid = result.result;
          return;
        }

        if (this.mapValidQuestions(this.applicantEligibilityQuestions, result)) {
          return true;
        }
        if (this.mapValidQuestions(this.applicantQuestions, result)) {
          return true;
        }
        if (this.mapValidQuestions(this.applicantVerificationQuestions, result)) {
          return true;
        }
      });

      this.validGroupEligibilityQuestions = this.groupEligibilityQuestions.filter(
        (question) => question.valid,
      );
      this.validGroupQuestions = this.groupQuestions.filter((question) => question.valid);
      this.validGroupVerificationQuestions = this.groupVerificationQuestions.filter(
        (question) => question.valid,
      );
      this.eligibleChildren.forEach((child) => {
        this.validApplicantQuestions[child.id] = this.applicantQuestions[child.id].filter(
          (question) => question.valid,
        );
      });

      this.validApplicantEligibilityQuestionsLength = this.lengthOfValidQuestions(
        this.applicantEligibilityQuestions,
      );
      this.validApplicantQuestionsLength = this.lengthOfValidQuestions(this.applicantQuestions);
      this.validApplicantVerificationQuestionsLength = this.lengthOfValidQuestions(
        this.applicantVerificationQuestions,
      );

      if (callback) {
        callback();
      }
    },

    lengthOfValidQuestions(questions) {
      const lengths = Object.values(questions).map(
        (questionObject) => questionObject.filter((question) => question.valid).length,
      );
      return lengths.reduce((total, length) => total + length, 0);
    },

    sortLinearQuestions(list, sortList) {
      if (!list || list.length == 0 || !sortList || sortList.length == 0) {
        return [];
      }

      const newList = [];

      sortList.forEach((id) => {
        const nextItem = list.find((item) => item.id == id);
        if (nextItem) newList.push(nextItem);
      });

      return newList;
    },

    mapValidQuestions(col, result) {
      const id = Object.keys(col).find((childId) => result.labels.includes(childId));
      if (id) {
        const foundQuestion = col[id].find((question) => result.labels.includes(question.id));

        if (foundQuestion) {
          foundQuestion.valid = result.result;
          return true;
        }
      }
      return false;
    },

    sortedQuestions(questions, syncedModel, eligibility) {
      return LinkedList.sort(
        questions.filter(
          (question) => question.synced_model == syncedModel && question.eligibility == eligibility,
        ),
      );
    },

    sortedVerification(questions, syncedModel) {
      return questions.filter(
        (question) => question.synced_model == syncedModel && question.verification,
      );
    },

    loadQuestions(callback) {
      this.api.public_api.organization.question.index(
        { owner_id: this.subsidyProgramId, owner_type: 'SubsidyProgram' },
        (response) => {
          this.questions = response.data;

          this.childEligibilityQuestions = this.sortedQuestions(
            this.questions,
            'Child',
            true,
          ).filter((question) => this.displayAll || question.published);

          this.childConditionalEligibilityQuestions = this.childEligibilityQuestions.filter(
            (question) => question.conditions.length > 0,
          );

          this.childQuestions = this.sortedQuestions(this.questions, 'Child', false).filter(
            (question) => this.displayAll || question.published,
          );

          this.childConditionalQuestions = this.childQuestions.filter(
            (question) => question.conditions.length > 0,
          );

          this.childVerificationQuestions = this.sortedVerification(this.questions, 'Child').filter(
            (question) => this.displayAll || question.published,
          );

          this.childConditionalVerificationQuestions = this.childVerificationQuestions.filter(
            (question) => question.conditions.length > 0,
          );

          this.groupEligibilityQuestions = this.sortedQuestions(
            this.questions,
            'Group',
            true,
          ).filter((question) => this.displayAll || question.published);

          this.groupQuestions = this.sortedQuestions(this.questions, 'Group', false).filter(
            (question) => this.displayAll || question.published,
          );

          this.groupConditionalQuestions = this.questions.filter(
            (question) => question.conditions.length > 0 && question.synced_model == 'Group',
          );

          this.groupVerificationQuestions = this.sortedVerification(this.questions, 'Group').filter(
            (question) => this.displayAll || question.published,
          );

          if (callback) callback();
        },
      );
    },

    loadSchemas() {
      this.api.public_api.organization.schema.index((resp) => {
        this.groupSchema = resp.data.find(
          (schema) => schema.id == this.subsidyProgram.group_schema_id,
        );
        this.childSchema = resp.data.find(
          (schema) => schema.id == this.subsidyProgram.child_schema_id,
        );
        if (this.subsidyProgram.provider_schema_id) {
          this.providerSchema = resp.data.find(
            (schema) => schema.id == this.subsidyProgram.provider_schema_id,
          );
        }
      });
    },
  },
};
