<template>
  <div>
    <v-menu
      v-model="favoriteMenuIsVisible"
      :close-on-content-click="false"
      :disabled="
        $store.state.profile.is_anonymous && $store.state.site.features.enable_registration
      "
    >
      <template #activator="{ props }">
        <v-btn
          @click.stop="showFavoriteMenu(false)"
          @keyup.enter.stop="showFavoriteMenu(false)"
          v-bind="props"
          ref="favoriteMenuActivator"
          :aria-label="ariaLabel"
          :ripple="false"
          data-cy="favorites_icon"
          size="small"
          variant="text"
          icon
        >
          <v-icon
            class="c-primary fs-24"
            size="30"
          >
            {{ favorited ? fullFavoriteIcon : emptyFavoriteIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-list class="fs-16">
        <template
          v-for="list in lists"
          :key="list.id"
        >
          <v-list-item
            v-if="
              !!favorites.find(
                (favorite) => favorite.list_id == list.id && favorite.provider_id == providerId,
              )
            "
            @click="toggleFavorite(list.id)"
            aria-live="polite"
          >
            <template #prepend>
              <v-icon color="primary">
                {{ fullFavoriteIcon }}
              </v-icon>
            </template>
            <v-list-item-title
              :aria-label="'Provider saved to list ' + list.name + '. Press enter to remove.'"
            >
              {{ list.name }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-else
            @click="toggleFavorite(list.id)"
            aria-live="polite"
          >
            <template #prepend>
              <v-icon color="primary"> radio_button_unchecked </v-icon>
            </template>
            <v-list-item-title :aria-label="'Press enter to add to list ' + list.name">
              {{ list.name }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <v-list-item
          @click="newListDialogIsVisible = true"
          data-cy="favorite-new-list"
        >
          <v-list-item-title>{{ $t('+ Add to new list') }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>

    <v-dialog
      v-model="newListDialogIsVisible"
      width="300px"
    >
      <v-card class="pa-4">
        <v-card-title>{{ $t('Save to new list') }}</v-card-title>
        <v-card-text class="pb-0">
          <v-text-field
            v-model="listName"
            :label="$t('List name')"
            variant="filled"
            autofocus
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="newListDialogIsVisible = false"
            class="focus-very-visible"
            variant="text"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            @click="createListAndFavorite"
            :disabled="!listName"
            class="focus-very-visible"
            data-cy="favorite-list-create"
            variant="text"
            primary
          >
            {{ $t('Create') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  compatConfig: { MODE: 3 },

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Favorite',

  props: {
    favorites: {
      type: Array,
      default: null,
    },
    lists: {
      type: Array,
      default: null,
    },
    providerId: {
      type: String,
      default: null,
    },
  },

  emits: ['favorite:add', 'favorite:list', 'favorite:remove'],

  data() {
    return {
      listName: new Date().toLocaleDateString(),
      emptyFavoriteIcon: this.$role === 'specialist' ? 'add_circle_outline' : 'favorite_border',
      favoriteMenuIsVisible: false,
      fullFavoriteIcon: this.$role === 'specialist' ? 'check_circle' : 'favorite',
      newListDialogIsVisible: false,
    };
  },

  computed: {
    ariaLabel() {
      if (this.favorited) {
        return 'Provider is a favorite';
      }
      return 'Save to favorites';
    },

    favorited() {
      if (this.favorites) {
        return !!this.favorites.find((favorite) => favorite.provider_id === this.providerId);
      }

      return false;
    },
  },

  watch: {
    favoriteMenuIsVisible(newVal) {
      if (!newVal && this.$refs.favoriteMenuActivator) {
        this.$nextTick(() => {
          this.$refs.favoriteMenuActivator.$el.focus();
        });
      }
    },

    newListDialogIsVisible(newVal) {
      if (!newVal) {
        this.$nextTick(() => {
          this.$refs.favoriteMenuActivator.$el.focus();
        });
      }
    },
  },

  methods: {
    createListAndFavorite() {
      this.$emit('favorite:list', [this.listName, this.providerId]);
      this.newListDialogIsVisible = false;
    },

    showFavoriteMenu(skipMenu) {
      if (
        this.$store.state.profile.is_anonymous === true &&
        this.$store.state.site.features.enable_registration
      ) {
        this.$router.push({
          name: 'Signup',
          query: {
            searchId: this.$route.params.searchId,
            providerId: this.providerId,
            page: this.$route.query.page,
            action: 'favorite',
          },
        });
      } else if (skipMenu === true) {
        this.newListDialogIsVisible = true;
      } else {
        this.favoriteMenuIsVisible = true;
      }
    },

    toggleFavorite(listId) {
      const favorite = this.favorites.find(
        (favorite) => favorite.provider_id === this.providerId && favorite.list_id === listId,
      );

      if (favorite) {
        this.$emit('favorite:remove', favorite.id);
      } else {
        this.$emit('favorite:add', [listId, this.providerId]);
      }
      this.favoriteMenuIsVisible = false;
    },
  },
};
</script>
