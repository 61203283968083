<template>
  <v-card
    :border="outlined"
    flat
    tile
  >
    <v-card-text>
      <v-row class="mb-0">
        <v-col class="d-flex align-center">
          <template v-if="search">
            <v-btn
              @click="$emit('close')"
              ref="backButton"
              class="focus-visible me-2"
              data-cy="match-details-back"
              size="small"
              variant="text"
              icon
            >
              <v-icon class="fs-24"> keyboard_backspace </v-icon>
            </v-btn>
            <v-btn
              @click="$emit('close')"
              id="back_to_results_link"
              :ripple="false"
              class="focus-visible px-0 fs-16 fw-400 bc-link-hover c-light-black"
              variant="text"
              tracked
              >{{ $t('Back to search results') }}</v-btn
            >
          </template>
          <v-spacer />
          <Favorite
            v-if="search && search.group_id"
            @favorite:add="$emit('favorite:add', $event)"
            @favorite:list="$emit('favorite:list', $event)"
            @favorite:remove="$emit('favorite:remove', $event)"
            ref="favorite"
            :favorites="favorites"
            :lists="lists"
            :provider-id="match.id"
          />
        </v-col>
      </v-row>

      <template v-if="match.closed_status != 'Open'">
        <v-row
          class="mb-3"
          dense
        >
          <v-col cols="12">
            <v-chip color="red">
              <v-icon class="c-white fs-18 me-2"> warning </v-icon>
              <span class="fw-600">
                {{ $t(match.closed_status) }}
              </span>
            </v-chip>
          </v-col>
        </v-row>
      </template>

      <v-row
        class="ma-0 pa-0"
        dense
      >
        <v-col
          class="ma-0 pa-0"
          cols="11"
        >
          <h2
            v-text="match.name"
            :title="match.name"
            class="fs-24 fw-600 c-black ma-0 pa-0"
            data-cy="match-details-name"
            data-testId="match-details-name"
            dense
          />
          <div
            v-if="match.license_business_name != match.name"
            class="ma-0 pa-0 mt-0"
            dense
          >
            {{ $t(match.license_business_name) }}
          </div>
        </v-col>
      </v-row>

      <v-row
        :class="canDisplayActionBar ? 'mb-0' : 'mb-6'"
        dense
      >
        <v-col>
          <div class="c-black fs-16 fw-400 ma-0 pa-0">
            <span
              v-text="[match.address1, match.city, match.zip].join(', ')"
              class="me-2 ma-0 pa-0"
              data-cy="match-details-address"
            />
            <span
              v-if="transitMinutes"
              data-cy="match-details-transit"
            >
              | {{ transitMinutes }} {{ $t('minutes total travel time') }}
            </span>
          </div>
        </v-col>
      </v-row>

      <template v-if="canDisplayActionBar">
        <v-row
          class="py-1"
          dense
        >
          <v-col class="mb-3">
            <v-btn
              v-if="schemaMeta.profile.enable_public_profile && $role == 'parent'"
              id="view_profile_link"
              :data-meta="data_meta"
              :to="{ name: 'PublicProviderShow', params: { providerId: match.id } }"
              class="c-white me-1 my-1 focus-visible fs-16 fw-400"
              color="primary"
              elevation="0"
              target="_blank"
              tracked
              >{{ $t('View profile') }}</v-btn
            >

            <v-btn
              v-if="$role == 'specialist'"
              id="edit_profile_link"
              :data-meta="data_meta"
              :to="{ name: 'ProviderShow', params: { providerId: match.id } }"
              class="c-white focus-visible me-1 my-1"
              color="primary"
              elevation="0"
              target="_blank"
              tracked
              >{{ $t('Edit Profile') }}</v-btn
            >

            <v-btn
              v-if="$role == 'parent' && canMessage && !existingMessage"
              @click="$refs.messageProviderDialog.open()"
              id="draft_message_button"
              :data-meta="data_meta"
              class="c-white focus-visible ms-2 me-1 my-1 fs-16"
              color="primary"
              elevation="0"
              tracked
            >
              {{ $t('Message') }}
            </v-btn>

            <v-btn
              v-if="$role == 'parent' && canMessage && existingMessage"
              @click="
                $store.state.pages.Messaging.enabled
                  ? $router.push({ name: 'MessageIndex', query: { provider_id: match.id } })
                  : null
              "
              id="draft_message_button"
              :data-meta="data_meta"
              class="bg-primary-light c-white focus-visible ms-2 me-1 my-1 fs-16"
              elevation="0"
              tracked
            >
              <v-icon class="me-2 fs-24"> check_circle </v-icon>
              {{ $t('Message sent') }}
            </v-btn>

            <v-btn
              v-if="$role == 'parent' && match.tours_enabled && match.tour_time && !existingTour"
              @click="$refs.bookTourDialog.open()"
              id="book_tour_button"
              class="c-white focus-visible ms-2 my-1 fs-16"
              color="primary"
              elevation="0"
              tracked
            >
              <span v-t="'Request a tour'" />
            </v-btn>

            <v-btn
              v-if="$role == 'parent' && match.tours_enabled && match.tour_time && existingTour"
              id="book_tour_button"
              class="c-white focus-visible ms-2 my-1 fs-16 bg-primary-light"
              elevation="0"
              tracked
            >
              <v-icon class="me-2 fs-24"> watch_later </v-icon>
              {{ $t('Tour requested') }}
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <ProviderImages
        :provider_id="match.id"
        class="my-6"
      />

      <AboutThisClassroom
        v-if="schemaMeta.profile.enable_description && match.description"
        :provider="match"
        class="ml-n4"
        style="border: none"
      />

      <template v-if="schemaMeta.profile.enable_emergency_response">
        <v-row class="px-3 mt-4">
          <EmergencyResponse
            v-if="match"
            :provider="match"
            class="mb-4"
          />
        </v-row>
      </template>

      <template v-if="match.public_notice">
        <v-row class="bt-1 bc-very-light-gray c-black">
          <v-col cols="12">
            <PublicNotice
              v-if="match.public_notice"
              :public-notice="match.public_notice"
            />
          </v-col>
        </v-row>
      </template>

      <template v-if="schemaMeta.profile.enable_contact">
        <v-row class="bt-1 bc-very-light-gray c-black pt-1">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t('Contact information') }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <template v-if="match.contact_name">
              <div class="c-black fs-16 fw-400 mb-2">
                <span v-text="match.contact_name" />
              </div>
            </template>
            <template v-if="match.phone || match.contact_phone">
              <div class="c-black fs-16 fw-400 mb-2">
                <template v-if="$vuetify.display.smAndDown">
                  <a
                    v-text="match.phone || match.contact_phone"
                    :href="'tel:' + match.phone"
                    class="underlined"
                  />
                </template>
                <template v-else>
                  <span v-text="match.phone || match.contact_phone" />
                </template>
              </div>
            </template>
            <template v-if="match.email || match.contact_email">
              <div
                v-text="match.email || match.contact_email"
                class="c-black fs-16 fw-400 mb-2"
              />
            </template>
            <template v-if="match.website">
              <div class="c-black fs-16 fw-400 mb-2 underlined">
                <a
                  :href="match.website"
                  data-tid="website_link"
                  target="_blank"
                  tracked
                  >{{ match.website }}</a
                >
              </div>
            </template>
          </v-col>
        </v-row>
      </template>

      <template v-if="schemaMeta.profile.enable_licensing">
        <v-row class="bt-1 bc-very-light-gray c-black pt-1">
          <v-col
            cols="12"
            g="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t(schemaMeta.profile.licensing_title) }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <div
              v-if="match.quality_rating_score > 0"
              class="mb-2 d-flex align-center"
            >
              <span class="me-1">{{ $t($store.state.site.ratings.name) }}:</span>
              <v-rating
                :length="$store.state.site.ratings.max"
                :model-value="match.quality_rating_score"
                class="me-2"
                color="#eecb54"
                density="compact"
                size="22"
                readonly
              >
                <template
                  v-if="$store.state.site.ratings.icons.length > 0"
                  #item="props"
                >
                  <CustomRating
                    :index="props.index"
                    :passed="props.isFilled"
                    :score="match.quality_rating_score"
                  />
                </template>
              </v-rating>

              <span class="c-light-black"
                >({{ match.quality_rating_score }} {{ $t('out of') }}
                {{ $store.state.site.ratings.max }})</span
              >
            </div>

            <div
              v-if="match.is_apost"
              class="mb-2"
            >
              <a
                href="https://www.afterschoolpgh.org"
                target="_blank"
              >
                <img
                  :src="$a.url('icons.apost')"
                  alt="APOST icon"
                  class="va-text-bottom me-2"
                  height="24"
                />
                <span>{{ $t('APOST Quality Campaign Member') }}</span>
              </a>
            </div>
            <div
              v-if="match.state_provider_id"
              class="mb-2"
            >
              <span
                >{{ $t(schema.definition.properties.state_provider_id.alias) }}
                {{ match.state_provider_id }}</span
              >
            </div>
            <div
              v-if="match.state_location_id_name && match.state_location_id"
              class="mb-2"
            >
              <span>{{ match.state_location_id_name }} {{ match.state_location_id }}</span>
            </div>
            <div
              v-if="match.license_type"
              class="mb-2"
            >
              <span>{{ $t(match.license_type) }}</span>
            </div>
            <div>
              <a
                v-if="!match.full_license && match.license_url"
                :href="match.license_url"
                class="c-primary fw-600"
                style="text-decoration: none"
                target="_blank"
              >
                <span
                  id="view_inspection_report_link"
                  :data-meta="data_meta"
                  class="me-1"
                  tracked
                >
                  {{ $t('View certificates and inspection reports') }}
                </span>
                <v-icon
                  class="fs-24"
                  color="primary"
                  >arrow_right</v-icon
                >
              </a>
              <div
                v-if="match.full_license"
                @click="showLicense()"
                class="c-primary fw-600 pointer"
              >
                <span class="me-1">{{ $t('View certificates and inspection reports') }}</span>
                <v-icon
                  class="fs-24"
                  color="primary"
                >
                  arrow_drop_down
                </v-icon>
              </div>
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-if="schemaMeta.profile.enable_licensing && license">
        <v-row class="bc-primary b-1 b-radius-8 mb-2 px-2">
          <v-col class="pb-1">
            <ProviderLicense
              v-if="license"
              :license="license"
            />
          </v-col>
        </v-row>
      </template>

      <template v-if="match.vacancy_descriptions">
        <v-row
          v-if="
            schemaMeta.openings.enabled && (schemaMeta.openings.public || $role == 'specialist')
          "
          class="bt-1 bc-very-light-gray c-black pt-2 pb-2"
        >
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t('Availability') }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <div
              v-for="(spot, index) in match.vacancy_descriptions"
              v-show="spot.vacant != null"
              :key="index"
              class="mb-2 d-flex align-center"
            >
              <v-icon
                v-if="spot.vacant"
                class="c-green me-2 fs-24"
              >
                check_circle
              </v-icon>
              <v-icon
                v-else
                class="c-red me-2 fs-24"
              >
                cancel
              </v-icon>
              <div class="fs-16 fw-400 c-black">
                <span v-if="spot.vacant">
                  {{ $t('Openings available : ') }}
                </span>
                <span v-else>
                  {{ $t('Openings not available for') }}
                </span>
                <LongDate
                  :date="spot.start_date"
                  :prefix="`${monthsToAgeString(spot.min)} - ${monthsToAgeString(spot.max)} as of`"
                />
              </div>
            </div>
            <div v-if="match.vacancy_descriptions.length == 0">
              <div
                v-if="match.openings_confirmed_at"
                class="mb-3"
              >
                <v-icon
                  class="me-2 fs-24"
                  color="red"
                >
                  cancel
                </v-icon>
                <span class="c-black fs-16 fw-400 va-middle">
                  {{ $t('No openings available') }}
                </span>
              </div>
              <div
                v-else
                class="mb-3"
              >
                <v-icon
                  class="me-2 fs-24"
                  color="#424242"
                >
                  help
                </v-icon>
                <span class="c-light-black fs-16 fw-400 va-middle">{{
                  $t('Availability unknown')
                }}</span>
              </div>
            </div>
            <div v-if="match.license_capacity">
              <span class="me-1">{{ $t('Capacity:') }}</span>
              <span v-text="match.license_capacity" />
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-if="match.program_types.length > 0">
        <v-row
          class="bt-1 bc-very-light-gray mb-2 pt-3"
          dense
        >
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t(`${terms.program} options`) }}
            </h3>
          </v-col>
          <v-col>
            <div
              v-for="(type, index) in match.program_types"
              :key="index"
              class="d-inline-block my-1 me-2"
            >
              <v-icon
                class="me-1 fs-24 va-text-bottom"
                color="primary"
              >
                check_circle
              </v-icon>
              <span
                v-text="$t(type)"
                class="fs-16 fw-400"
              />
            </div>
          </v-col>
        </v-row>
      </template>

      <ProviderPrograms
        v-if="schemaMeta.profile.enable_programs && match.count_of_programs > 0"
        :provider="match"
        :rows="1"
        class="mb-0"
      />

      <template v-if="schemaMeta.profile.enable_classroom_count && match.count_of_programs > 0">
        <v-row class="bt-1 bc-very-light-gray c-black pt-1 mb-2">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t(`${terms.programs}`) }}
            </h3>
          </v-col>
          <v-col
            cols="12"
            lg="8"
            xl="9"
          >
            <div class="c-black fs-16 fw-400">
              {{ `${match.count_of_programs} ${terms.programs.toLowerCase()}` }}
            </div>
          </v-col>
        </v-row>
      </template>

      <template
        v-if="
          schemaMeta.profile.enable_programs &&
          match.count_of_programs == 0 &&
          agesServed.length > 0
        "
      >
        <v-row class="bt-1 bc-very-light-gray c-black pt-1">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t('Ages served') }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <div
              v-for="(age, index) in agesServed"
              v-text="$t(age.name)"
              :key="index"
              class="c-black fs-16 fw-400 mb-2"
            />
          </v-col>
        </v-row>
      </template>

      <template v-if="schemaMeta.profile.enable_payment_details">
        <PaymentDetails :provider="match" />
      </template>

      <template
        v-if="
          schemaMeta.profile.enable_hours &&
          match.hours_descriptions &&
          match.hours_descriptions.length > 0
        "
      >
        <v-row class="bt-1 bc-very-light-gray c-black pt-1">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t('Hours of operation') }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <HoursChunks :chunks="match.hours_chunks" />
            <div
              v-if="match.hours_other_description"
              v-text="match.hours_other_description"
              class="c-black fs-16 fw-400 mb-2"
            />
            <div v-if="match.program_season">
              <v-icon
                class="va-text-bottom me-2 fs-24"
                color="secondary"
              >
                date_range
              </v-icon>
              <span class="c-black fw-400 fs-16 fw-400"
                >{{ $t(`${terms.program} offered`) }} {{ $t(match.program_season) }}.</span
              >
            </div>
          </v-col>
        </v-row>
      </template>

      <template v-if="enrollmentSteps.length > 0">
        <v-row class="bt-1 bc-very-light-gray c-black pt-1">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t(`How to ${terms.enroll.toLowerCase()}`) }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <EnrollmentSteps :steps="enrollmentSteps" />
          </v-col>
        </v-row>
      </template>

      <template v-if="schemaMeta.profile.enable_highlights && match.has_highlights">
        <v-row class="bt-1 bc-very-light-gray c-black mb-2 pt-1">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t('Highlights') }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <Highlights
              v-if="match"
              :provider="match"
              :schema="schema"
            />
          </v-col>
        </v-row>
      </template>

      <template v-if="match.count_of_staff_members > 0">
        <v-row class="bt-1 bc-very-light-gray c-black mb-2 pt-1">
          <v-col
            cols="12"
            lg="4"
            xl="3"
          >
            <h3 class="fs-18 fw-600">
              {{ $t('Staff members') }}
            </h3>
          </v-col>
          <v-col
            class="c-black fs-16 fw-400"
            cols="12"
            lg="8"
            xl="9"
          >
            <StaffMembers
              v-if="match"
              :provider="match"
            />
          </v-col>
        </v-row>
      </template>
    </v-card-text>

    <MessageProviderDialog
      v-if="$role == 'parent' && match"
      @messaged="existingMessage = $event"
      ref="messageProviderDialog"
      :provider="match"
    />
    <BookTourDialog
      v-if="$role == 'parent' && match"
      @booked="existingTour = $event"
      ref="bookTourDialog"
      :provider="match"
    />
  </v-card>
</template>

<script>
import AboutThisClassroom from '@/public/components/provider/AboutThisClassroom.vue';
import AgeCalculations from '@/shared/mixins/age_calculations';
import API from '@/shared/mixins/api';
import BookTourDialog from '@/shared/components/BookTourDialog.vue';
import CustomRating from '@/shared/components/CustomRating.vue';
import EmergencyResponse from '@/public/components/provider/EmergencyResponse.vue';
import EnrollmentSteps from '@/shared/components/search/EnrollmentSteps.vue';
import Favorite from '@/shared/components/search/favorite.vue';
import Highlights from '@/shared/components/search/highlights.vue';
import HoursChunks from '@/public/components/provider/HoursChunks.vue';
import ProviderImages from '@/public/components/provider/ProviderImages.vue';
import LongDate from '@/shared/components/LongDate.vue';
import MessageProviderDialog from '@/shared/components/MessageProviderDialog.vue';
import PaymentDetails from '@/shared/components/search/payment_details.vue';
import ProviderLicense from '@/shared/components/provider/ProviderLicense.vue';
import ProviderPrograms from '@/shared/components/provider/ProviderPrograms.vue';
import StaffMembers from '@/public/components/provider/StaffMembers.vue';
import Terms from '@/shared/mixins/terms';
import PublicNotice from '@/shared/components/PublicNotice.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    AboutThisClassroom,
    BookTourDialog,
    CustomRating,
    EmergencyResponse,
    EnrollmentSteps,
    Favorite,
    Highlights,
    HoursChunks,
    LongDate,
    MessageProviderDialog,
    PaymentDetails,
    ProviderImages,
    ProviderLicense,
    ProviderPrograms,
    StaffMembers,
    PublicNotice,
  },

  mixins: [AgeCalculations, API, Terms],

  props: {
    favorites: {
      type: Array,
      default: null,
    },
    lists: {
      type: Array,
      default: null,
    },
    match: {
      type: Object,
      default: null,
    },
    outlined: {
      type: Boolean,
      default: true,
    },
    schema: {
      type: Object,
      default: null,
    },
    search: {
      type: Object,
      default: null,
    },
  },

  emits: ['close', 'favorite:add', 'favorite:list', 'favorite:remove'],

  data() {
    return {
      contact: null,
      enrollmentSteps: [],
      existingMessage: null,
      existingTour: null,
      license: null,
      spots: [],
    };
  },

  computed: {
    agesServed() {
      if (!this.match.ages) return [];
      return this.match.ages.filter((age) => age.accepted);
    },

    canDisplayActionBar() {
      if (!this.search) return false;
      if (this.match.schema_id != this.schema.id) return false;

      return true;
    },

    canMessage() {
      if (this.$store.state.profile && this.$store.state.profile.contact && this.contact) {
        return (
          this.match.inquiries_enabled &&
          this.$store.state.profile.contact.provider_inquiries &&
          this.$store.state.site.features.enable_messaging &&
          this.contact.provider_inquiries
        );
      }
      return false;
    },

    data_meta() {
      return JSON.stringify({ provider_id: this.match.id });
    },

    schemaMeta() {
      return this.schema?.meta;
    },

    transitMinutes() {
      if (!this.match.transit_time) return null;
      return parseInt(this.match.transit_time / 60, 10);
    },
  },

  watch: {
    'match.id': {
      immediate: true,
      handler() {
        this.existingMessage = null;
        this.existingTour = null;
        this.loadContact();
        this.loadEnrollmentSteps();
        if (this.$route.query.action === 'message') {
          this.$nextTick(() => {
            this.$refs.messageProviderDialog.open();
            this.$router.push({ query: {} });
          });
        } else if (this.$route.query.action === 'tour') {
          this.$nextTick(() => {
            this.$refs.bookTourDialog.open();
            this.$router.push({ query: {} });
          });
        }
      },
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.setDefaultState();
    });
  },

  unmounted() {
    this.$emit('unmounted');
  },

  methods: {
    setDefaultState() {
      if (
        this.match.id === this.$route.query.providerId &&
        this.$route.query.action === 'favorite'
      ) {
        this.$refs.favorite.showFavoriteMenu(true);
        this.$router.push({ query: {} });
      } else {
        this.$nextTick(() => {
          this.$refs.backButton?.$el?.focus();
        });
      }
    },

    async loadContact() {
      if (!this.match.email) {
        this.contact = null;
        return true;
      }

      const { data } = await this.api.contact.get(this.match.email);
      this.contact = data;
      return true;
    },

    async loadEnrollmentSteps() {
      const { data } = await this.api.public_api.provider.enrollment_steps.index(this.match.id);
      this.enrollmentSteps = data;
    },

    async showLicense() {
      if (this.license) {
        this.license = null;
        return true;
      }

      const { data } = await this.api.public_api.provider.license.get(this.match.id);
      this.license = data;
      return true;
    },
  },
};
</script>

<style>
.v-btn:before {
  opacity: 0 !important;
}

.v-ripple__container {
  opacity: 0 !important;
}
a span:hover {
  text-decoration: underline !important;
}
</style>
