export const householdMemberDefaults = {
  dob: null,
  first_namе: null,
  gender: null,
  group_id: null,
  id: null,
  last_name: null,
  marital_status: null,
  middle_name: null,
  race: [],
  relationship: null,
  schema_id: null,
  ssn: null,
};
