<template>
  <div>
    <v-row>
      <v-col>
        <v-row
          v-for="(key, index) in keys"
          :key="index"
        >
          <v-col
            class="d-flex flex-nowrap"
            cols="12"
          >
            <div class="pe-3">
              <v-icon
                color="secondary"
                size="24"
              >
                {{ schemaProperties().highlights.properties[key].icon }}
              </v-icon>
            </div>
            <div>
              <div
                v-t="schemaProperties().highlights.properties[key].alias"
                class="fs-16 fw-500 mb-1"
              />
              <translated-items
                v-if="provider.highlights[key] && provider.highlights[key].length > 0"
                :items="provider.highlights[key]"
                classes="fs-16 fw-400"
              />
              <div
                v-else
                class="fs-16 fw-400 c-light-black"
              >
                {{ $t('Not available') }}
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row v-if="provider.other_programs_description">
      <v-col>
        <div class="fs-18 fw-600">
          <span v-t="otherProgramsDescriptionTitle()" />
        </div>
        <div
          v-text="provider.other_programs_description"
          class="fs-16 fw-400"
        />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import TranslatedItems from '@/shared/components/TranslatedItems.vue';

export default {
  compatConfig: { MODE: 2 },

  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Highlights',
  components: {
    'translated-items': TranslatedItems,
  },

  props: {
    provider: {
      type: Object,
      default: null,
    },
    schema: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      keys: this.getEnabledKeys(),
    };
  },

  methods: {
    getEnabledKeys() {
      function enabledKeys(key) {
        return this.schemaProperties().highlights.properties[key].enabled === true;
      }

      return Object.keys(this.schemaProperties().highlights.properties).filter(enabledKeys, this);
    },

    otherProgramsDescriptionTitle() {
      return (
        this.schemaProperties().other_programs_description.alias || 'Other program information:'
      );
    },

    schemaProperties() {
      return this.schema.definition.properties;
    },
  },
};
</script>
