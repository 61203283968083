<template>
  <div v-if="localChildren && localChildren.length > 0">
    <v-row
      v-for="(child, index) in localChildren"
      :key="child.id"
    >
      <v-col>
        <SubsidyChild
          @change="updateChild($event)"
          @removal="remove(child)"
          :autofocus="autofocus"
          :index="index"
          :initial-child="child"
          :prevent-update="preventUpdate"
          :staged="staged"
          included-check
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col class="fs-15">
        {{ $t("Don't see the child you're looking for?") }}
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-btn
          @click="addChild"
          :loading="processing"
          class="focus-very-visible"
          color="primary"
        >
          <v-icon start> add </v-icon>
          <span v-t="'Add another child'" />
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Api from '@/shared/services/bright_finder';
import SubsidyChild from '@/parent/components/subsidy/SubsidyChild.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    SubsidyChild,
  },

  props: {
    autofocus: Boolean,
    children: {
      default: null,
      type: Array,
    },
    preventUpdate: Boolean,
    staged: {
      default: false,
      type: Boolean,
    },
    subsidies: {
      default: null,
      type: Array,
    },
  },

  emits: ['change'],

  data() {
    return {
      localChildren: this.children,
      processing: false,
      profile: {},
    };
  },

  created() {
    if (this.localChildren.length === 0) {
      this.addChild();
    }
  },

  methods: {
    addChild() {
      if (this.staged) {
        this.localChildren.push({});
      } else {
        this.processing = true;
        Api.child.create(this.defaultChild(), (resp) => {
          const newChild = resp.data;
          newChild.included = true;
          newChild.editing = true;
          this.localChildren.push(newChild);
          this.$emit('change', this.localChildren);
          this.processing = false;
        });
      }
    },

    defaultChild() {
      return {
        schema_id: Object.values(this.$store.state.schemas).filter(
          (schema) => schema.data_type === 'Child',
        )[0].id,
      };
    },

    remove(child) {
      this.localChildren.splice(
        this.localChildren.findIndex((localChild) => localChild.id === child.id),
        1,
      );
      this.$emit('change', this.localChildren);
    },

    updateChild(child) {
      this.$set(
        this.localChildren,
        this.localChildren.findIndex((localChild) => localChild.id === child.id),
        child,
      );
      this.$emit('change', this.localChildren);
    },
  },
};
</script>
