<template>
  <span class="lh-20">
    <StructuredTextElement
      v-for="(element, index) in text"
      :key="index"
      :element="element"
    />
  </span>
</template>

<script>
import StructuredTextElement from '@/shared/components/content/StructuredTextElement.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    StructuredTextElement,
  },

  props: {
    text: {
      type: Array,
      default: null,
    },
  },
};
</script>
