<template>
  <div
    v-if="match"
    class="c-black"
  >
    <v-row
      class="d-flex align-top"
      dense
    >
      <v-col cols="11">
        <div
          v-if="match.accepts_subsidy || match.closed_status != 'Open'"
          class="mb-3"
        >
          <v-chip
            v-if="match.closed_status != 'Open'"
            class="me-2"
            color="red"
            label
          >
            <v-icon
              class="c-white fs-16 me-2"
              size="24"
            >
              warning
            </v-icon>
            <span class="fw-600">
              {{ $t(match.closed_status) }}
            </span>
          </v-chip>
          <v-chip
            v-if="match.accepts_subsidy"
            color="secondary"
            variant="outlined"
            label
          >
            {{ $t('Accepts Financial Aid') }}
          </v-chip>
        </div>
        <h2
          class="c-black fw-600 fs-20"
          data-cy="match-name"
          dense
        >
          {{ $t(match.name) }}
        </h2>
        <div
          v-if="match.license_business_name != match.name"
          class="fs-16"
        >
          {{ match.license_business_name }}
        </div>
      </v-col>
      <v-col
        class="d-flex justify-end"
        cols="1"
      >
        <b-favorite
          v-if="search.group_id"
          @favorite:add="$emit('favorite:add', $event)"
          @favorite:list="$emit('favorite:list', $event)"
          @favorite:remove="$emit('favorite:remove', $event)"
          :ref="'favorite_' + match.id"
          :favorites="favorites"
          :lists="lists"
          :provider-id="match.id"
        />
      </v-col>
    </v-row>

    <v-row
      class="ma-0 pa-0"
      dense
    >
      <v-col>
        <span
          class="fs-16 fw-400"
          data-cy="match-address"
          >{{ match.address1 }}, {{ match.city }}, {{ match.zip }},
          {{
            match.driving_distance || match.distance_from_origin || match.distance_from_destination
          }}
          {{ $t('miles away') }}</span
        >
      </v-col>
    </v-row>

    <v-row
      v-if="schema.meta.card.enable_summary"
      class="mb-1"
      dense
    >
      <v-col class="px-3">
        <v-list
          class="pb-0"
          slim
        >
          <v-list-item
            v-if="match.quality_rating_score > 0"
            :min-height="0"
            class="pa-0 my-1"
          >
            <template #prepend>
              <img
                v-if="$store.state.site.ratings.icons && $store.state.site.ratings.icons.length > 0"
                :alt="$store.state.site.ratings.name + ' rating'"
                :src="$store.state.site.ratings.icons[0].pass"
                height="24"
              />
              <v-icon
                v-else
                alt=""
                color="#FFD200"
                role="presentation"
                size="24"
              >
                star
              </v-icon>
            </template>
            <v-list-item-title
              v-if="match.quality_rating_score"
              class="fs-16 fw-400"
            >
              <span
                >{{ $t($store.state.site.ratings.name) }}: {{ match.quality_rating_score }}
                {{ $t('out of') }} {{ $store.state.site.ratings.max }}
              </span>
            </v-list-item-title>
            <v-list-item-title
              v-else
              class="fs-16 fw-400"
            >
              {{ $t('No state rating.') }}
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="match.is_apost"
            :min-height="0"
            class="pa-0 my-1"
          >
            <template #prepend>
              <img
                :src="$a.url('icons.apost')"
                alt="APOST quality rating icon"
                height="24"
              />
            </template>
            <v-list-item-title class="fs-16 fw-400">
              <span>{{ $t('APOST Quality Campaign Member') }}</span>
            </v-list-item-title>
          </v-list-item>
          <template
            v-if="
              schema.meta.openings.enabled && (schema.meta.openings.public || $role == 'specialist')
            "
          >
            <v-list-item
              v-for="(spot, index) in match.vacancy_descriptions"
              v-show="spot.vacant != null"
              :key="index"
              :min-height="0"
              class="pa-0 my-1"
            >
              <template #prepend>
                <v-icon
                  v-if="spot.vacant"
                  class="c-green"
                  size="24"
                >
                  check_circle
                </v-icon>
                <v-icon
                  v-else
                  class="c-red"
                  size="24"
                >
                  cancel
                </v-icon>
              </template>
              <v-list-item-title class="fs-16 fw-400">
                <span v-if="spot.vacant">
                  {{ $t('Openings available : ') }}
                </span>
                <span v-else>
                  {{ $t('Openings not available for') }}
                </span>
                <LongDate
                  :date="spot.start_date"
                  :prefix="`${monthsToAgeString(spot.min)} - ${monthsToAgeString(spot.max)} as of`"
                />
              </v-list-item-title>
            </v-list-item>
          </template>
          <v-list-item
            v-if="schema.meta.card.enable_hours"
            :min-height="0"
            class="pa-0 my-1"
          >
            <template #prepend>
              <v-icon
                class="c-black"
                size="24"
              >
                query_builder
              </v-icon>
            </template>

            <v-list-item-title class="fs-16 fw-400">
              <HoursChunks
                v-if="match.hours_chunks"
                :chunks="match.hours_chunks"
              />
            </v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="match.payment_subsidies_accepted.length > 0"
            class="pa-0 my-1"
          >
            <template #prepend>
              <v-icon
                color="#578052"
                size="24"
              >
                attach_money
              </v-icon>
            </template>
            <v-list-item-title class="c-black fs-16 fw-400">
              {{ $t('Financial assistance options available') }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-row
      v-if="match.categories.length > 0"
      class="mb-0 mt-2"
      dense
    >
      <v-col class="">
        <v-chip
          v-for="(category, index) in match.categories"
          :key="index"
          class="me-1"
        >
          {{ category }}
        </v-chip>
      </v-col>
    </v-row>

    <v-row
      v-if="match.private_notice"
      dense
    >
      <v-col class="bg-primary">
        <div class="pa-2 fs-14 c-white">
          <v-icon
            class="c-white fs-16 me-2"
            size="24"
          >
            announcement
          </v-icon>
          <span class="fw-600 me-1">Internal only:</span>
          <span v-text="match.private_notice" />
        </div>
      </v-col>
    </v-row>

    <v-row
      v-if="match.public_notice"
      dense
    >
      <v-col class="bg-primary">
        <div class="pt-2 px-2 fs-14 c-white">
          <v-icon
            class="c-white fs-16 me-2"
            size="24"
          >
            info
          </v-icon>
          <span class="fw-600 me-1">{{ $t('Please note') }}:</span>
          <MarkdownContent :content="match.public_notice" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import AgeCalculations from '@/shared/mixins/age_calculations';
import Favorite from '@/shared/components/search/favorite.vue';
import HoursChunks from '@/public/components/provider/HoursChunks.vue';
import LongDate from '@/shared/components/LongDate.vue';
import MarkdownContent from '@/shared/components/MarkdownContent.vue';

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Match',

  compatConfig: { MODE: 2 },

  components: {
    HoursChunks,
    LongDate,
    MarkdownContent,
    BFavorite: Favorite,
  },

  mixins: [AgeCalculations],

  props: {
    favorites: {
      type: Array,
      default: null,
    },
    lists: {
      type: Array,
      default: null,
    },
    match: {
      type: Object,
      default: null,
    },
    schema: {
      type: Object,
      default: null,
    },
    search: {
      type: Object,
      default: null,
    },
  },

  emits: ['favorite:add', 'favorite:list', 'favorite:remove'],

  data() {
    return {
      loaded: false,
      spots: [],
    };
  },

  computed: {
    position() {
      return { lat: this.match.latitude, lng: this.match.longitude };
    },
  },
};
</script>
