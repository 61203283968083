export class RouteDispatcher {
  constructor(map) {
    this.map = map;
    if (!this.directionsService) {
      this.directionsService = new google.maps.DirectionsService();
    }
    if (!this.directionsDisplay) {
      this.directionsDisplay = new google.maps.DirectionsRenderer({
        polylineOptions: { strokeColor: '#1F5E70', strokeWeight: '6' },
      });
      this.directionsDisplay.setMap(map);
      this.secondaryDirectionsDisplay = new google.maps.DirectionsRenderer({
        polylineOptions: { strokeColor: '#1F5E70', strokeWeight: '6' },
      });
      this.secondaryDirectionsDisplay.setMap(map);
    }
  }

  clear() {
    this.directionsDisplay.setDirections({ routes: [] });
    this.secondaryDirectionsDisplay.setDirections({ routes: [] });
  }

  dispatch(origin, destination, travel_mode, waypoints = [], callback) {
    this.clear();
    const vm = this;
    if (travel_mode === 'TRANSIT') {
      vm.directionsService.route(
        {
          origin,
          destination: waypoints[0] ? waypoints[0].location : destination,
          travelMode: 'TRANSIT',
        },
        (response, status) => {
          if (status === 'OK') {
            vm.directionsDisplay.setOptions({ suppressMarkers: true, preserveViewport: true });
            vm.directionsDisplay.setDirections(response);
            let duration = response.routes[0].legs.reduce(
              (sum, leg) => sum + leg.duration.value,
              0,
            );
            if (waypoints[0]) {
              vm.directionsService.route(
                { origin: waypoints[0].location, destination, travelMode: 'TRANSIT' },
                (response, status) => {
                  if (status === 'OK') {
                    vm.secondaryDirectionsDisplay.setOptions({
                      suppressMarkers: true,
                      preserveViewport: true,
                    });
                    vm.secondaryDirectionsDisplay.setDirections(response);
                    if (callback) {
                      duration += response.routes[0].legs.reduce(
                        (sum, leg) => sum + leg.duration.value,
                        0,
                      );
                      callback(duration);
                    }
                  }
                },
              );
            } else if (callback) {
              callback(duration);
            }
          }
        },
      );
    } else {
      vm.directionsService.route(
        {
          origin,
          destination,
          travelMode: travel_mode || 'DRIVING',
          waypoints,
        },
        (response, status) => {
          if (status === 'OK') {
            vm.directionsDisplay.setOptions({ suppressMarkers: true, preserveViewport: true });
            vm.directionsDisplay.setDirections(response);
            if (callback) {
              callback(response.routes[0].legs.reduce((sum, leg) => sum + leg.duration.value, 0));
            }
          } else {
            console.error('Dispatch error:', response);
          }
        },
      );
    }
  }
}
