import Api from '@/specialist/services/bright_finder';

export default {
  page() {
    if (window.ga) {
      window.ga('set', 'page', window.location.pathname);
      window.ga('send', 'pageview');
    }

    Api.user_event.create();
  },

  identify() {},

  async setProfileAndTimeout(context, profile) {
    await context.commit('setProfile', profile);
    context.commit('resetTimeout');
  },
};
