<template>
  <v-container
    v-if="schema"
    class="mxw-800"
  >
    <v-card
      border
      flat
      tile
    >
      <v-card-text class="py-0 mnh-250">
        <template v-if="questions.length > 0">
          <QuestionSet
            v-model="survey"
            @back="backFromQuestion($event)"
            @next="forwardFromQuestion($event)"
            :processing="processing"
            :questions="validQuestions"
            :reversible="index > 0"
            :schema="schema.definition"
            :section="section"
            :transition-name="transitionName"
            key-name="question"
          />
        </template>

        <template v-else>
          <v-progress-linear
            class="my-8"
            indeterminate
          />
        </template>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import API from '@/shared/mixins/api';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import Questionable from '@/shared/mixins/questionable';
import Stepper from '@/shared/mixins/stepper';

export default {
  compatConfig: { MODE: 2 },

  components: {
    QuestionSet,
  },

  mixins: [API, Questionable, Stepper],

  data() {
    return {
      index: 0,
      processing: false,
      questions: [],
      schema: null,
      survey: {
        custom: {},
      },
    };
  },

  watch: {
    '$route.params.schemaId': {
      immediate: true,
      handler() {
        this.load();
      },
    },
  },

  methods: {
    async backFromQuestion(index) {
      this.processing = true;
      if (index - 1 < 0) {
        this.processing = false;
      } else {
        this.goToQuestion(index - 1);
      }
    },

    async forwardFromQuestion(index) {
      this.processing = true;
      await this.validate();
      if (index + 1 >= this.validQuestions.length) {
        this.submitSurvey();
      } else {
        this.goToQuestion(index + 1);
      }
    },

    goToQuestion(newIndex) {
      this.processing = false;
      this.section = null;
      this.index = newIndex;
      setTimeout(() => {
        this.section = `question-${newIndex}`;
      }, 600);
    },

    async load() {
      await this.loadSchema();
      await this.loadQuestions();
      const resp = await this.api.member
        .create({
          default_locale: this.$i18n.locale,
          is_anonymous: true,
          organization_id: window.organization_id,
          is_parent: true,
        })
        .catch((error) => this.$eventBus.$emit('error', error));
      if (resp) this.section = 'question-0';
    },

    async loadQuestions() {
      const resp = await this.api.public_api.organization.question.index({
        owner_id: this.schema.id,
        owner_type: 'Schema',
      });
      this.questions = resp.data;
      this.validate();
    },

    async loadSchema() {
      const resp = await this.api.public_api.organization.schema
        .get(this.$route.params.schemaId)
        .catch(() => this.$eventBus.$emit('longChime', 'Survey could not be found'));
      if (!resp) return;

      this.schema = resp.data;
    },

    async submitSurvey() {
      this.survey.schema_id = this.schema.id;
      const resp = await this.api.parent.survey
        .create(this.survey)
        .catch((error) => this.$eventBus.$emit('error', error));
      this.processing = false;
      if (!resp) return;

      this.processing = false;
      if (this.schema.meta.submitted_redirect_url)
        window.location.replace(this.schema.meta.submitted_redirect_url);
      else this.$router.push({ name: 'Dashboard' });
    },
  },
};
</script>
