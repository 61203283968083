<template>
  <v-card
    :href="$store.state.pages.Search.program_alert.url"
    class="bg-secondary-extra-light px-0 mt-4 mb-0"
    border
    flat
    tile
  >
    <v-card-text class="pb-0">
      <v-row dense>
        <v-col
          v-if="$vuetify.display.mdAndUp && $store.state.pages.Search.program_alert.logo"
          class="d-flex align-stretch pt-4 px-4"
          cols="3"
        >
          <img
            :src="$store.state.pages.Search.program_alert.logo"
            alt="Image of children playing"
          />
        </v-col>
        <v-col class="pa-4">
          <h1
            v-if="$store.state.pages.Search.program_alert.title"
            class="c-primary fs-22 fw-600 mb-2"
          >
            {{ $t($store.state.pages.Search.program_alert.title) }}
          </h1>
          <div
            v-if="$store.state.pages.Search.program_alert.description"
            class="fs-16 fw-500"
          >
            {{ $t($store.state.pages.Search.program_alert.description) }}
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions class="pa-0 mnh-0">
      <v-btn
        :aria-label="
          [
            $store.state.pages.Search.program_alert.title,
            $store.state.pages.Search.program_alert.description,
            title,
          ].join(', ')
        "
        class="d-flex align-center focus-very-visible justify-space-between"
        color="secondary"
        height="unset"
        max-width="1"
        min-height="40"
        block
        tile
      >
        <span
          class="fw-600 fs-22 text-wrap"
          data-cy="apply_workflow"
        >
          {{ $t(title) }}
        </span>
        <template #append>
          <v-icon class="fs-28"> arrow_circle_right </v-icon>
        </template>
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    route: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      title: this.$store.state.pages.Search.program_alert.cta || 'Learn more and apply',
    };
  },
};
</script>
