<template>
  <v-card border>
    <v-card-text>
      <div
        v-if="child.editing"
        class="mb-4"
      >
        <v-row class="fs-16">
          <LabeledTextfield
            v-model="child.first_name"
            :aria-label="$t('Enter a first name')"
            :autofocus="autofocus"
            :dense="dense"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            field="first_name"
            message="First name"
            sm="4"
            mandatory
          />
          <LabeledTextfield
            v-model="child.middle_name"
            :aria-label="$t('Enter an optional middle name')"
            :dense="dense"
            :placeholder="$t('Optional')"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            field="middle_name"
            message="Middle name"
            sm="4"
          />
          <LabeledTextfield
            v-model="child.last_name"
            :aria-label="$t('Enter a last name')"
            :dense="dense"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            field="last_name"
            message="Last name"
            sm="4"
            mandatory
          />
        </v-row>
        <v-row class="fs-16">
          <LabeledDateOfBirth
            v-model="child.dob"
            :aria-label="$t('Enter a date of birth')"
            :dense="dense"
            :readonly="readonly"
            :schema-id="child.schema_id"
            cols="12"
            data-cy="dob-select"
            field="dob"
            message="Date of birth"
            mandatory
          />
        </v-row>
      </div>
      <v-row class="d-flex align-center">
        <v-col class="d-flex align-center">
          <v-icon
            v-if="invalid && !child.editing"
            class="c-red me-3"
            size="26"
          >
            error_outline
          </v-icon>
          <template v-if="!child.editing">
            <div>
              <template v-if="child.first_name && child.last_name">
                <div
                  v-text="[child.first_name, child.last_name].join(' ')"
                  class="d-block fs-16 fw-600"
                  data-cy="child-name"
                />
              </template>
              <template v-else>
                <div
                  v-t="'Incomplete name'"
                  class="d-block fs-16 fw-600 c-red"
                />
              </template>
              <template v-if="child.dob">
                <div class="d-block">
                  <LongDate
                    :date="child.dob"
                    class="fs-16"
                    data-cy="child-dob"
                    timezone="UTC"
                  />
                </div>
              </template>
              <template v-else>
                <div
                  v-t="'Missing date of birth'"
                  class="d-block fs-16 fw-600 c-red"
                />
              </template>
            </div>
          </template>
        </v-col>
        <v-col class="d-flex justify-end">
          <template v-if="child.editing">
            <v-btn
              @click="update()"
              :loading="processing"
              :ripple="false"
              class="focus-very-visible me-3"
              color="primary"
              data-cy="done-button"
            >
              <span v-t="'Done'" />
            </v-btn>
          </template>
          <template v-else>
            <v-btn
              @click="child.editing = true"
              :ripple="false"
              class="focus-very-visible me-3"
              color="primary"
              variant="text"
            >
              {{ $t('Edit') }}
            </v-btn>
            <template v-if="child.included">
              <v-btn
                @click="child.included = false"
                :loading="processing"
                :ripple="false"
                class="focus-very-visible"
                color="primary"
              >
                <v-icon start> check_box </v-icon>
                <span v-t="'Included in application'" />
              </v-btn>
            </template>
            <template v-else>
              <v-btn
                @click="child.included = true"
                :loading="processing"
                :ripple="false"
                class="focus-very-visible"
                color="primary"
                variant="outlined"
              >
                <v-icon start> check_box_outline_blank </v-icon>
                <span v-t="'Include in application'" />
              </v-btn>
            </template>
          </template>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import Api from '@/specialist/services/bright_finder';
import LabeledDateOfBirth from '@/shared/components/form/LabeledDateOfBirth.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LongDate from '@/shared/components/LongDate.vue';

export default {
  compatConfig: { MODE: 2 },

  components: {
    LabeledDateOfBirth,
    LabeledTextfield,
    LongDate,
  },

  props: {
    autofocus: Boolean,
    dense: Boolean,
    index: { type: Number, default: null },
    initialChild: { type: Object, default: null },
    preventUpdate: Boolean,
    readonly: Boolean,
    removeDisabled: {
      type: Boolean,
      default: false,
    },
    staged: {
      default: false,
      type: Boolean,
    },
  },

  emits: ['change', 'removal'],

  data() {
    return {
      child: this.initialChild,
      processing: false,
    };
  },

  computed: {
    invalid() {
      return !this.child.first_name || !this.child.last_name || !this.child.dob;
    },
  },

  methods: {
    remove() {
      if (this.staged) return;
      Api.child.destroy(this.child.id, () => {
        this.$emit('removal', this.child);
      });
    },

    update() {
      if (!this.staged) {
        if (this.preventUpdate) {
          this.$emit('change', this.child);
        } else {
          this.processing = true;
          Api.child.update(
            this.child.id,
            this.child,
            (resp) => {
              const newVersion = resp.data;
              newVersion.included = this.child.included;
              newVersion.editing = false;
              this.child = newVersion;
              this.$emit('change', this.child);
              this.processing = false;
            },
            (error) => {
              this.$eventBus.$emit('chime', error.response.data.errors[0]);
            },
          );
        }
      }
    },
  },
};
</script>
