export const getSuggestions = async (
  searchText,
  { resultsForState = [], operatingCountry = 'US' } = {},
) => {
  let result;

  try {
    const rawResult = await searchLocation(searchText, operatingCountry);

    const stateLockedResults = rawResult.filter((result) =>
      resultsForState.some((state) => result.structured_formatting.secondary_text.includes(state)),
    );
    const searchResults = resultsForState.length > 0 ? stateLockedResults : rawResult;

    result = searchResults.map((res) => ({
      id: res.place_id,
      value: res.description,
    }));
  } catch (_err) {
    result = null;
  }
  return result;
};

// Auxiliary functions
// wrap google api's callback to an async function
export const searchLocation = (val, operatingCountry) => {
  const promise = new Promise((resolve, reject) => {
    const displaySuggestions = (predictions, status) => {
      if (status !== google.maps.places.PlacesServiceStatus.OK) {
        reject(status);
      }
      resolve(predictions);
    };

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions(
      {
        input: val,
        types: ['geocode'],
        componentRestrictions: { country: operatingCountry },
      },
      displaySuggestions,
    );
  });

  return promise;
};
