export default {
  ageGroups: [],
  appBarBack: null,
  appBarChip: null,
  brand: {},
  config: {},
  confirmed: false,
  default_locale: 'en',
  drawer: null,
  features: {},
  filters: {},
  fullscreen: false,
  group: { meta: { members: [] }, id: null },
  identified: false,
  languages: [],
  lastViewedSubsidyProgramId: null,
  miniNav: false,
  navigation: null,
  resultsMode: 'list',
  resultsPreviousMode: 'list',
  pages: {},
  profile: null,
  progressBarValue: 0,
  providers: {},
  role: null,
  schemas: {},
  search: null,
  search_success: false,
  site: null,
  siteWebpageId: null,
  user_location: null,
  userSessionTimeout: null,
  userSessionActionTimestamp: null,
};
