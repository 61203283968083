<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <v-row>
      <v-col>
        <v-btn
          @click="value.location_type = 'home'"
          ref="locationHome"
          :variant="value.location_type != 'home' ? 'outlined' : 'flat'"
          class="focus-very-visible me-3 my-1"
          color="primary"
          data-cy="home_tab"
        >
          {{ $t('Home') }}
        </v-btn>
        <v-btn
          @click="value.location_type = 'work'"
          :variant="value.location_type != 'work' ? 'outlined' : 'flat'"
          class="focus-very-visible me-3 my-1"
          color="primary"
          data-cy="work_tab"
        >
          {{ $t('Work') }}
        </v-btn>
        <v-btn
          @click="value.location_type = 'zip'"
          :variant="value.location_type != 'zip' ? 'outlined' : 'flat'"
          class="focus-very-visible me-3 my-1"
          color="primary"
          data-cy="zip_tab"
          >{{ $t('Zip code') }}</v-btn
        >
        <v-btn
          @click="value.location_type = 'commute'"
          :variant="value.location_type != 'commute' ? 'outlined' : 'flat'"
          class="focus-very-visible me-3 my-1"
          color="primary"
          data-cy="commute_tab"
        >
          {{ $t('Along my commute') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col
        v-show="value.location_type == 'commute'"
        class="y-0 c-black fs-16 ta-left"
        >{{ $t('From:') }}</v-col
      >
    </v-row>
    <v-row dense>
      <v-col>
        <MaskedInput
          v-if="value.location_type == 'zip'"
          v-model="value.zip"
          @update:model-value="setLocation('zip')"
          v-slot="{ inputRef, masked }"
          mask="#####"
        >
          <v-text-field
            v-model="masked.value"
            @keyup.enter="checkDone"
            id="search_origin_zip"
            :ref="inputRef"
            :items="originLocationFoundItems"
            :placeholder="$t('Enter a location')"
            aria-label="Enter a zip"
            class="dark-placeholder mt-0 ta-left"
            color="primary"
            data-cy="enter-zip-text"
            item-title="value"
            item-value="id"
            variant="outlined"
            auto-select-first
            hide-details
            hide-no-data
          />
        </MaskedInput>
        <template v-else>
          <v-autocomplete
            v-model="origin"
            v-model:search="originSearchText"
            @keyup.enter="checkDone"
            @update:model-value="setLocation('origin')"
            id="search_origin_address"
            ref="origin_autocomplete"
            :items="originLocationFoundItems"
            :placeholder="$t('Enter a location')"
            aria-label="Enter a location"
            class="dark-placeholder mt-0 ta-left"
            color="primary"
            data-cy="enter-location-auto"
            item-title="value"
            item-value="id"
            variant="outlined"
            auto-select-first
            autofocus
            hide-details
            hide-no-data
            no-filter
            return-object
          />
          <div
            v-if="!value.formatted_origin"
            class="fs-12 mt-1 mb-0"
          >
            {{ $t('Select a location from options list') }}
          </div>
        </template>
      </v-col>
    </v-row>
    <div
      v-if="value.location_type == 'commute'"
      class="mt-3"
    >
      <v-row dense>
        <v-col class="py-0 c-black fs-16 ta-left">
          {{ $t('To:') }}
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-autocomplete
            v-model="destination"
            v-model:search="destinationSearchText"
            @keyup.enter="checkDone"
            @update:model-value="setLocation('destination')"
            id="search_destination_address"
            ref="destination_autocomplete"
            :items="destinationLocationFoundItems"
            :placeholder="$t('Enter a location')"
            aria-label="Enter your destination location"
            class="dark-placeholder mt-0 ta-left"
            item-title="value"
            item-value="id"
            variant="outlined"
            auto-select-first
            hide-details
            hide-no-data
            return-object
          />
        </v-col>
      </v-row>
    </div>
    <v-row
      v-if="!hideClose"
      class="mt-1 ta-right"
    >
      <v-col>
        <v-btn
          @click="$emit('close')"
          class="focus-very-visible"
          color="primary"
          data-cy="location_done"
        >
          {{ $t('Done') }}
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
/* eslint-disable vue/no-mutating-props, vue/multi-word-component-names, no-console */
import { getSuggestions } from '@/plugins/get-suggestions';

export default {
  compatConfig: { MODE: 3 },

  name: 'Location',

  props: {
    hideClose: {
      default: false,
      type: Boolean,
    },
    open: {
      default: null,
      type: Boolean,
    },
    value: {
      default: null,
      type: Object,
    },
  },

  emits: ['change', 'close'],

  data() {
    return {
      destination: null,
      destinationSearchText: null,
      destinationLocationEntries: [],
      origin: null,
      originLocationEntries: [],
      originSearchText: null,
      not: ['v-btn--outlined', 'primary'],
      pressed: ['v-btn--depressed', 'primary'],
    };
  },
  computed: {
    destinationLocationFoundItems() {
      return this.destinationLocationEntries;
    },

    originLocationFoundItems() {
      return this.originLocationEntries;
    },
  },

  watch: {
    destinationSearchText(newVal) {
      if (!newVal || newVal.length <= 2) return;

      getSuggestions(newVal, {
        resultsForState: this.$store.state.config.operating_states,
        operatingCountry: this.$store.state.config.operating_country,
      })
        .then((res) => {
          this.destinationLocationEntries = res;
        })
        .catch((err) => {
          console.error('Location suggestion error:', err);
        });
    },

    originSearchText(newVal) {
      if (!newVal || newVal.length <= 2) return;

      getSuggestions(newVal, {
        resultsForState: this.$store.state.config.operating_states,
        operatingCountry: this.$store.state.config.operating_country,
      })
        .then((res) => {
          this.originLocationEntries = res ?? [];
        })
        .catch((err) => {
          console.error('Location suggestion error:', err);
        });
    },

    value: {
      immediate: true,
      handler(newVal, prevVal) {
        if (newVal && !prevVal) {
          this.origin = newVal.origin || {};
          this.destination = newVal.destination || {};

          this.origin.value = newVal.formatted_origin || this.backfill_formatted_address('origin');
          this.destination.value =
            newVal.formatted_destination || this.backfill_formatted_address('destination');

          if (this.origin.place_id) {
            this.origin.id = this.origin.place_id;
            this.originLocationEntries.push(this.origin);
          }

          if (this.destination.place_id) {
            this.destination.id = this.destination.place_id;
            this.destinationLocationEntries.push(this.destination);
          }

          if (this.origin.value && !this.value.formatted_origin) {
            this.value.formatted_origin = this.origin.value;
          }

          if (this.origin.value && !this.value.formatted_origin) {
            this.value.formatted_origin = this.origin.value;
          }
        }
      },
    },

    // This exists because destination is hidden at first render - something necessary only because a Vuetify bug that causes the outlined label to break when v-show is used instead of v-if
    'value.location_type'(newVal) {
      this.value.origin = null;
      this.value.destination = null;
      this.value.formatted_origin = null;
      this.value.formatted_destination = null;

      if (newVal === 'commute') {
        this.value.origin = this.value.home;
        if (this.value.home) {
          this.origin = {
            value: this.value.home.place_id,
            text: this.backfill_formatted_address('origin'),
          };
        } else {
          this.origin = null;
        }

        this.value.destination = this.value.work;
        if (this.value.destination) {
          this.destination = {
            value: this.value.destination.place_id,
            text: this.backfill_formatted_address('destination'),
          };
        } else {
          this.destination = null;
        }

        this.value.formatted_origin = this.backfill_formatted_address('origin');
        this.value.formatted_destination = this.backfill_formatted_address('destination');
      } else {
        this.value.origin = this.value[newVal];
        if (this.value[newVal]) {
          this.origin = {
            value: this.value[newVal].place_id,
            text: this.backfill_formatted_address('origin'),
          };
        } else {
          this.origin = null;
        }
        this.value.formatted_origin = this.backfill_formatted_address('origin');
        if (this.validChange()) {
          this.$emit('change', this.value);
        }
      }
    },
  },

  methods: {
    backfill_formatted_address(field) {
      if (!this.value[field] || !this.value[field].latitude) {
        return null;
      }
      if (!this.value[field].address) {
        return [this.value[field].city, this.value[field].state].join(', ');
      }
      if (this.value[field].address === this.value[field].city) {
        return [this.value[field].address, this.value[field].state].join(', ');
      }
      return [this.value[field].address, this.value[field].city, this.value[field].state].join(
        ', ',
      );
    },

    checkDone() {
      if (this.value.location_type === 'commute') {
        if ((this.origin && this.destination) || (this.value.zip && this.value.zip.length === 5)) {
          this.$emit('close');
        }
      } else if (this.origin) {
        this.$emit('close');
      }
    },

    setLocation(field) {
      if (field === 'zip') {
        if (this.value.zip.length === 5) {
          this.value.formatted_origin = this.value.zip;
        }
      } else if (this[field]) {
        this.value[field] = { place_id: this[field].id };
        this.value[`formatted_${field}`] = this[field].value;
      } else {
        this.value[field] = null;
        this.value[`formatted_${field}`] = null;
      }

      if (this.validChange()) {
        this.$emit('change', this.value);
      }
    },

    validChange() {
      if (this.value.location_type === 'zip') {
        return this.value.zip && this.value.zip.length === 5;
      }
      if (this.value.location_type === 'commute') {
        return (
          this.value.origin &&
          this.value.destination &&
          this.value.formatted_destination &&
          this.value.formatted_origin
        );
      }
      return this.value.origin && this.value.formatted_origin;
    },
  },
};
</script>
