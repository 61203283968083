<template>
  <v-row
    v-if="hasAnyPaymentDetails"
    id="financial"
    class="bt-1 bc-very-light-gray c-black mb-2 pt-1"
  >
    <v-col
      cols="12"
      lg="4"
      xl="3"
    >
      <div class="fw-600 fs-18">
        {{ $t('Financial programs') }}
      </div>
    </v-col>
    <v-col
      class="c-black fs-16 fw-500 mxw-400"
      cols="12"
      lg="8"
      xl="9"
    >
      <v-row
        v-if="provider.accepts_subsidy"
        class="fs-16 fw-400"
        dense
      >
        <v-col class="d-flex align-center">
          <v-icon class="va-text-bottom me-1 c-green fs-24"> check_circle </v-icon>
          <span class="fs-16 fw-400 fs-16">{{
            $t('This child care provider accepts financial aid')
          }}</span>
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_subsidies_accepted.length > 0"
        dense
      >
        <v-col cols="1">
          <v-icon
            class="fs-24"
            color="#578052"
          >
            attach_money
          </v-icon>
        </v-col>
        <v-col cols="11">
          <div class="fs-16 fw-500 mb-1">
            {{ $t('Financial assistance options available') }}
          </div>
          <div
            v-for="(subsidy, index) in provider.payment_subsidies_accepted"
            :key="index"
            v-t="subsidy"
            class="fs-16 fw-400"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_other_programs.length > 0"
        class="mt-2"
        dense
      >
        <v-col cols="1">
          <img
            :src="$a.url('icons.headstart')"
            alt="Head Start icon"
            class="h-24"
          />
        </v-col>
        <v-col cols="11">
          <div class="fs-16 fw-500 mb-1">
            {{
              $t(
                $store.state.schemas[provider.schema_id].definition.properties
                  .payment_other_programs.alias,
              ) || $t('Head Start programs offered')
            }}
          </div>
          <div
            v-for="(program, index) in provider.payment_other_programs"
            v-text="program"
            :key="index"
            class="fs-16 fw-400"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_other_description"
        class="mt-2"
        dense
      >
        <v-col cols="1">
          <v-icon
            class="fs-24"
            color="primary"
          >
            loyalty
          </v-icon>
        </v-col>
        <v-col cols="11">
          <div class="fs-16 fw-500 mb-1">
            {{ $t('Discounts') }}
          </div>
          <div
            v-text="provider.payment_other_description"
            class="fs-16 fw-400"
          />
        </v-col>
      </v-row>
      <v-row
        v-if="provider.payment_methods_accepted.length > 0"
        class="mt-2"
        dense
      >
        <v-col cols="12">
          <div class="fs-16 fw-500 mb-2">
            {{ $t('Payment options:') }}
          </div>
          <div
            v-for="(method, index) in provider.payment_methods_accepted"
            :key="index"
            class="d-inline-block me-3"
          >
            <v-icon
              class="me-1 fs-24 va-text-bottom"
              color="primary"
            >
              check_circle
            </v-icon>
            <span
              v-text="$t(method)"
              class="fs-16 fw-400"
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
export default {
  compatConfig: { MODE: 2 },

  props: {
    provider: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hasAnyPaymentDetails() {
      return (
        this.provider.payment_methods_accepted.length > 0 ||
        this.provider.payment_subsidies_accepted.length > 0 ||
        this.provider.payment_other_programs.length > 0 ||
        this.provider.payment_other_description ||
        this.provider.accepts_subsidy
      );
    },
  },
};
</script>
