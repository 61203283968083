<template>
  <GoogleMapMarker
    @click="$emit('click')"
    :clickable="true"
    :options="{
      position: { lat: match.latitude, lng: match.longitude },
      icon: {
        url: icon.url,
        scaledSize: selected
          ? {
              width: (width || icon.width) * 2,
              height: (height || icon.height) * 1.25,
              f: 'px',
              b: 'px',
            }
          : { width: width || icon.width, height: height || icon.height, f: 'px', b: 'px' },
      },
    }"
  />
</template>

<script setup>
import { Marker as GoogleMapMarker } from 'vue3-google-map';
import { useStore } from 'vuex';

defineEmits(['click']);
const props = defineProps({
  height: {
    type: Number,
    default: null,
  },
  match: {
    type: Object,
    default: null,
  },
  selected: {
    type: Boolean,
    default: null,
  },
  width: {
    type: Number,
    default: null,
  },
});

const store = useStore();

const icon = computed(() => {
  if (props.match.closed) {
    return { url: store.state.pages.Search.markers.closed, width: 30, height: 30 };
  }
  if (props.match.has_vacancies) {
    if (props.match.quality_rating_score > store.state.site.ratings.min) {
      return {
        url: store.state.pages.Search.markers.available_and_rated,
        width: 60,
        height: 30,
      };
    }
    return { url: store.state.pages.Search.markers.available, width: 30, height: 30 };
  }
  if (props.match.quality_rating_score > store.state.site.ratings.min) {
    return { url: store.state.pages.Search.markers.rated, width: 30, height: 30 };
  }
  return { url: store.state.pages.Search.markers.no_vacancy, width: 30, height: 30 };
});
</script>
